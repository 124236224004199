import { Button, Grid, TextField } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { useAppDispatch } from "../../../store";
import { updateOwnPassword } from "../../store/auth.slice";

interface IChangeOwnPasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ChangeOwnPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { handleSubmit, control, errors, formState, reset, watch, trigger } = useForm<IChangeOwnPasswordForm>({
    mode: "onChange",
    defaultValues: { oldPassword: "", newPassword: "", confirmPassword: "" },
  });
  const { touched } = formState;

  const onSubmit = useCallback(
    async (data: IChangeOwnPasswordForm) => {
      const { oldPassword, newPassword } = data;

      try {
        await dispatch(updateOwnPassword({ oldPassword, newPassword })).unwrap();
        // TODO: add snackbar through dispatch (global notification module)
      } catch (_) {
        reset();
      }
      reset();
    },
    [dispatch, reset]
  );

  const watchNewPassword = watch("newPassword");
  useEffect(() => {
    if (!touched.confirmPassword) {
      return;
    }
    trigger("confirmPassword");
  }, [watchNewPassword, trigger, touched.confirmPassword]);

  return (
    <Grid component={"form"} onSubmit={handleSubmit(onSubmit)} container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Controller
          name={"oldPassword"}
          control={control}
          rules={{ required: "Поле должно быть заполнено" }}
          render={(field) => (
            <TextField
              {...field}
              type={"password"}
              fullWidth={true}
              label={"Старый пароль"}
              error={!!errors.oldPassword}
              helperText={errors.oldPassword ? errors.oldPassword.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Controller
          name={"newPassword"}
          control={control}
          rules={{
            required: "Поле должно быть заполнено",
            minLength: { value: 8, message: "Пароль должен содержать более 8 символов" },
          }}
          render={(field) => (
            <TextField
              {...field}
              type={"password"}
              fullWidth={true}
              label={"Новый пароль"}
              error={!!errors.newPassword}
              helperText={errors.newPassword ? errors.newPassword.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Controller
          name={"confirmPassword"}
          control={control}
          rules={{
            required: "Поле должно быть заполнено",
            validate: (value) => value === watchNewPassword || "Пароли не совпадают",
          }}
          render={(field) => (
            <TextField
              {...field}
              type={"password"}
              fullWidth={true}
              label={"Повторить пароль"}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item={true} xs={12}>
        <Button disabled={!formState.isValid} type={"submit"} color={"primary"} variant={"contained"}>
          Изменить
        </Button>
      </Grid>
    </Grid>
  );
};
