import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { ITechOperationEditingState } from "../shared/interfaces/tech-operation-editing-state";
import { MODULE_NAME } from "./tech-operation-editing.slice";

//eslint-disable-next-line
export const getTechOperationEditingState = (rootState: any): ITechOperationEditingState => rootState[MODULE_NAME];

export const getModel = createDraftSafeSelector(
  getTechOperationEditingState,
  (state: ITechOperationEditingState) => state.techOperation
);

export const getAssetTypes = createDraftSafeSelector(
  getTechOperationEditingState,
  (state: ITechOperationEditingState) => state.assetTypes
);

export const getAssets = createDraftSafeSelector(
  getTechOperationEditingState,
  (state: ITechOperationEditingState) => state.assets
);

export const getLoading = createDraftSafeSelector(
  getTechOperationEditingState,
  (state: ITechOperationEditingState) => state.isLoading
);
