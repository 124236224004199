import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { NotificationSeverity } from "../shared/enums/notification-severity";
import { NotificationType } from "../shared/enums/notification-type";
import { INotification } from "../shared/interfaces/notification";
import { INotificationsState } from "../shared/interfaces/notifications-state";

const initialState: INotificationsState = {
  systemNotifications: [],
};

const defaultNotification = {
  type: NotificationType.Alert,
  message: "",
  title: "",
  severity: NotificationSeverity.Info,
  cb: () => void 0,
};

export const MODULE_NAME = "Notifications";

export const notificationsSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    addNotificationAction(state, action: PayloadAction<Partial<INotification>>): void {
      state.systemNotifications.push({
        ...defaultNotification,
        ...action.payload,
      });
    },
    removeNotificationAction(state, action: PayloadAction<INotification>): void {
      const id = state.systemNotifications.indexOf(action.payload);
      if (id === null) {
        return;
      }
      state.systemNotifications.splice(id, 1);
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;

export const { addNotificationAction, removeNotificationAction } = notificationsSlice.actions;
