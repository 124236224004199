import { Box, Grid } from "@material-ui/core";
import React, { PropsWithChildren } from "react";

import { FarmLandsSubmenu } from "../farm-lands-submenu/farm-lands-submenu";

type IProps = PropsWithChildren<{ submenuChildren?: JSX.Element }>;

export const DetailsLayout = (props: IProps): JSX.Element => {
  const { children, submenuChildren = <></> } = props;

  return (
    <Box p={1}>
      <Grid container={true} direction="row" wrap="nowrap">
        <Grid item={true}>
          <Grid container={true} direction="column">
            <Grid item={true}>
              <FarmLandsSubmenu />
            </Grid>

            <Grid item={true}>{submenuChildren}</Grid>
          </Grid>
        </Grid>

        <Grid item={true} style={{ flexGrow: 1 }}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
