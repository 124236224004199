import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import { deletePestsAction, fetchPestsAction, getSelectedIds, setSearchText, setSelectedIdsAction } from "../../store";

const PestList = lazy(() => import("../pests-list/pests-list"));

const PestsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedIds = useSelector(getSelectedIds);

  const onDelete = useCallback(
    () => dispatch(deletePestsAction(selectedIds)).then(() => dispatch(fetchPestsAction())),
    [selectedIds, dispatch]
  );

  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.PESTS}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <PestList />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default PestsTab;
