import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { format as formatDate } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { HUMAN_DATE_FORMAT } from "../../../../constant";
import { getCropTypesList } from "../../../../modules/crop-types";
import { useDisableGlobalFilters } from "../../../../modules/filter";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { getInfoPhenoPhases, getInfoSeasons } from "../../../../modules/info-data";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFInputHidden } from "../../../../shared/components/react-hook-form-mui/input-hidden";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { isEditingPage } from "../../../../shared/utils/is-editing-page";
import { isEmpty } from "../../../../shared/utils/is-empty";
import { useAppDispatch } from "../../../../store";
import { IFarmLandDto } from "../../../fields/shared/dtos/farm-land.dto";
import { fetchFarmLandsByFarmId } from "../../../fields/store/farm-lands.slice";
import { ObservationPhotosUpload } from "../../components/observation-photos-upload/observation-photos-upload";
import { ObservationViolationsList } from "../../components/observation-violations-list/observation-violations-list";
import { IPhenoPhaseDto } from "../../shared/dtos/phenophase.dto";
import { IObservationFormData } from "../../shared/interfaces/observation-form-data";
import { Observation } from "../../shared/models/observation";
import { dtoToObservationFormData } from "../../shared/utils/dto-to-observation-form-data";
import { updateObservationFromFormData } from "../../shared/utils/update-observation-from-form-data";
import { getIsLoading } from "../../store/observation-editing.selector";
import {
  addObservationAction,
  fetchObservationByIdAction,
  updateObservationAction,
  uploadChosenPhotos,
} from "../../store/observation-editing.slice";

export const ObservationsEditingForm = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const sourceFarmLandId = searchParams.get("fieldId");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState<{
    type: AlertProps["severity"];
    text: JSX.Element | string;
  } | null>(null);
  const appFilterFarmId = useSelector((state) => getFilter(state, FilterName.FarmId));
  const appFilterSeasonId = useSelector((state) => getFilter(state, FilterName.SeasonId));
  const [farmLands, setFarmLands] = useState<IFarmLandDto[]>([]);
  const cropTypes = useSelector(getCropTypesList);
  const phenoPhases = useSelector(getInfoPhenoPhases);
  const seasons = useSelector(getInfoSeasons);
  const isLoading = useSelector(getIsLoading);
  const [seasonDates, setSeasonDates] = useState<{
    startedAt?: number;
    finishedAt?: number;
  }>({});
  const defaultValues = useMemo(
    () => dtoToObservationFormData(new Observation().setFarmLandId(sourceFarmLandId).asDto),
    [sourceFarmLandId]
  );
  const [editedModel, setEditedModel] = useState<null | Observation>(null);

  const rhfMethods = useForm<IObservationFormData>({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues,
  });

  const watchFields = rhfMethods.watch();

  useDisableGlobalFilters();

  // Utility getters
  const getSeasonDates = useCallback(
    (seasonId) => {
      const currentSeason = findModelByProperty(seasons, seasonId);
      setSeasonDates({
        startedAt: currentSeason?.startedAt,
        finishedAt: currentSeason?.finishedAt,
      });
    },
    [seasons]
  );

  // Update lists
  const updateFarmLandsList = useCallback(
    async (farmId, seasonId) => {
      const list = await dispatch(
        fetchFarmLandsByFarmId({
          farmId,
          excludeGeometry: true,
          filter: {
            where: {
              seasonId,
              isDeleted: false,
            },
          },
        })
      ).unwrap();
      list.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
      setFarmLands(list);
    },
    [dispatch]
  );

  useEffect(() => {
    getSeasonDates(appFilterSeasonId);
  }, [appFilterSeasonId, getSeasonDates]);

  const isEditingObservation = useMemo(() => isEditingPage(id), [id]);

  useEffect(() => {
    (async () => {
      if (!appFilterFarmId || !appFilterSeasonId) {
        return;
      }

      updateFarmLandsList(appFilterFarmId, appFilterSeasonId);

      // id already included in isEditing... but ts compiler doesn't see it
      if (id && isEditingObservation) {
        const model = await dispatch(fetchObservationByIdAction(id)).unwrap();
        setEditedModel(model); // save full model to later updates
        rhfMethods.reset(dtoToObservationFormData(model.asDto));
        await rhfMethods.trigger();

        if (model.seasonId) {
          getSeasonDates(model.seasonId);
        }
        return;
      }

      rhfMethods.setValue("farmId", appFilterFarmId);
      rhfMethods.setValue("seasonId", appFilterSeasonId);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appFilterFarmId, appFilterSeasonId, id, updateFarmLandsList, setEditedModel]);

  const onSubmit = useCallback(
    async (data) => {
      // TODO: shouldn't call if errors exists (remove and check it)
      if (!isEmpty(rhfMethods.formState.errors)) {
        return;
      }

      let newModel = new Observation(data.id);

      if (isEditingObservation && editedModel) {
        newModel.updateFromDto(editedModel.asDto);
      }

      updateObservationFromFormData(newModel, data);
      newModel.setAppUserId(localStorage.getItem("user_id") as string);

      try {
        newModel = await dispatch(
          isEditingObservation ? updateObservationAction(newModel) : addObservationAction(newModel)
        ).unwrap();

        rhfMethods.reset(dtoToObservationFormData(newModel.asDto));

        await dispatch(uploadChosenPhotos()).unwrap();
        newModel.completeReport();

        newModel = await dispatch(updateObservationAction(newModel)).unwrap();

        // TODO: use notification actions to user communication
        if (isEditingObservation) {
          setMessage({ type: "success", text: "Отчет осмотра поля сохранен" });
          return;
        }

        navigate(`../${newModel.id}`, {
          relative: "path", // navigate relative to path (not router tree)
          replace: true,
        });
      } catch (err) {
        setMessage({ type: "error", text: "Произошла ошибка, попробуйте позже" });
        throw err;
      }
    },
    [dispatch, navigate, isEditingObservation, rhfMethods, editedModel]
  );

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onMessageHide = useCallback((_, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage(null);
  }, []);

  useEffect(() => {
    (async () => {
      if (!farmLands.length || !watchFields.farmLandId) {
        return;
      }

      const farmLand = findModelByProperty(farmLands, watchFields.farmLandId);

      if (rhfMethods.formState.touched.farmLandId || rhfMethods.formState.dirtyFields.farmLandId) {
        rhfMethods.formState.touched.farmLandId = true;
        rhfMethods.setValue("cropTypeId", farmLand?.cropTypeId);
        rhfMethods.setValue("phenoPhaseId", null);
        rhfMethods.setValue("violations", []);
      }
    })();
    // eslint-disable-next-line
  }, [farmLands, watchFields.farmLandId]);

  const chosenFarmLandCropName = useMemo(() => {
    const chosenFarmLand = findModelByProperty(farmLands, watchFields.farmLandId);
    const chosenFarmLandCrop = findModelByProperty(cropTypes, chosenFarmLand?.cropTypeId);
    return chosenFarmLandCrop?.name || "";
  }, [cropTypes, farmLands, watchFields.farmLandId]);

  const availablePhenoPhases = useMemo(() => {
    if (!watchFields.farmLandId || !phenoPhases.length) {
      return [];
    }
    const chosenFarmLand = findModelByProperty(farmLands, watchFields.farmLandId);
    return phenoPhases.filter((item) => item.cropTypeId === chosenFarmLand?.cropTypeId).sort((a, b) => a.name - b.name);
  }, [farmLands, phenoPhases, watchFields.farmLandId]);

  // observationDate TODO: move it in single component
  const minDate = useMemo(() => new Date(seasonDates?.startedAt || 0), [seasonDates?.startedAt]);
  const maxDate = useMemo(
    () =>
      seasonDates?.finishedAt && seasonDates.finishedAt < new Date().getTime()
        ? new Date(seasonDates.finishedAt)
        : new Date(),
    [seasonDates?.finishedAt]
  );

  // TODO: try to substitute this logic (it needs to apply min/max validation bounds)
  useEffect(() => {
    rhfMethods.trigger("observationDate");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonDates, watchFields.observationDate]);

  const isSubmitDisabled = useMemo(() => {
    return !isEmpty(rhfMethods.formState.errors) || isLoading;
  }, [rhfMethods.formState, isLoading]);

  return (
    <CommonLayout title={"Отчёты осмотров"}>
      <Paper>
        <FormProvider {...rhfMethods}>
          <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
            <RHFInputHidden name="id" defaultValue={""} />
            <RHFInputHidden name="farmId" defaultValue={""} />
            <RHFInputHidden name="seasonId" defaultValue={""} />
            <RHFInputHidden name="cropTypeId" defaultValue={""} />

            <List>
              <ListItem>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <RHFAutocomplete<IFarmLandDto>
                      name="farmLandId"
                      rules={{ required: true }}
                      renderValue={(value) => findModelByProperty(farmLands, value)}
                      AutocompleteProps={{
                        disabled: !farmLands.length || Boolean(sourceFarmLandId) || isEditingObservation,
                        options: farmLands,
                        noOptionsText: "В хозяйстве нет полей",
                      }}
                      TextFieldProps={{
                        required: true,
                        label: "Поле",
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={6}>
                    <Box pt={3}>{chosenFarmLandCropName}</Box>
                  </Grid>

                  <Grid item={true} xs={6}>
                    {/* TODO: now we have a rule if page is add then we must fill phenoPhase, if edit, then not (need to fix it) */}
                    <RHFAutocomplete<IPhenoPhaseDto>
                      name="phenoPhaseId"
                      rules={isEditingObservation ? {} : { required: true }}
                      renderValue={(value) => findModelByProperty(phenoPhases, value)}
                      AutocompleteProps={{
                        disabled: !watchFields.farmLandId,
                        options: availablePhenoPhases,
                        noOptionsText: "Нет стадий роста",
                      }}
                      TextFieldProps={{
                        required: !isEditingObservation,
                        label: "Стадия роста",
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={6}>
                    <Controller
                      control={rhfMethods.control}
                      name={"observationDate"}
                      rules={{
                        required: true,
                        validate: (data: Date) => data >= minDate && data <= maxDate,
                      }}
                      render={({ ref, ...rest }) => (
                        <DatePicker
                          {...rest}
                          label={"Дата отчета"}
                          fullWidth={true}
                          cancelLabel={"Отменить"}
                          okLabel={"Подтвердить"}
                          format={HUMAN_DATE_FORMAT}
                          animateYearScrolling={true}
                          error={!!rhfMethods.errors.observationDate}
                          invalidDateMessage={"Некорректный формат даты. Ожидаемый формат дд/мм/гггг"}
                          minDateMessage={`Дата не должна быть раньше ${formatDate(minDate, HUMAN_DATE_FORMAT)}`}
                          maxDateMessage={`Дата не должна быть позднее ${formatDate(maxDate, HUMAN_DATE_FORMAT)}`}
                          minDate={minDate}
                          maxDate={maxDate}
                          InputLabelProps={{
                            required: true,
                          }}
                          InputProps={{
                            required: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <RHFTextField
                  name={"comment"}
                  TextFieldProps={{
                    label: "Комментарий",
                  }}
                />
              </ListItem>

              <ObservationViolationsList />

              <ListItem>
                <Typography variant={"h5"}>Фотографии отчета</Typography>
              </ListItem>

              <Box px={2} py={1}>
                <ObservationPhotosUpload />
              </Box>
            </List>

            <Divider />

            <Box p={1}>
              <Grid container={true} justifyContent={"center"} spacing={2}>
                <Grid item={true}>
                  <Button type="submit" color={"primary"} variant={"contained"} disabled={isSubmitDisabled}>
                    Сохранить
                  </Button>
                </Grid>

                <Grid item={true}>
                  <Button color={"primary"} variant={"outlined"} onClick={onCancel}>
                    Отмена
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>

        {message && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={onMessageHide}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={message.type}>{message.text}</Alert>
          </Snackbar>
        )}
      </Paper>
    </CommonLayout>
  );
};
