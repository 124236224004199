import { ActionReducerMapBuilder, Draft, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { isEditingPage } from "../../../shared/utils/is-editing-page";
import { ITechOperationEditingState } from "../shared/interfaces/tech-operation-editing-state";
import { TechOperation } from "../shared/models/tech-operation";
import { TechOperationAsset } from "../shared/models/tech-operation-asset";
import { TechOperationAssetType } from "../shared/models/tech-operation-assetType";
import { techOperationsAssetsService } from "../shared/services/tech-operations-assets.service";
import { techOperationsService } from "../shared/services/tech-operations.service";

const initialState: ITechOperationEditingState = {
  isLoading: false,
  techOperation: new TechOperation(),
  assets: [],
  assetTypes: [],
};

export const MODULE_NAME = "techOperationEditingPage";
export const techOperationEditingSlice = createSlice({
  name: MODULE_NAME,
  initialState: initialState,
  reducers: {
    setTechOperationData(state, action: PayloadAction<TechOperation>): void {
      state.techOperation = action.payload;
    },
    setTechOperationAssetTypes(state, action: PayloadAction<TechOperationAssetType[]>): void {
      state.assetTypes = action.payload;
    },
    setTechOperationAssets(state, action: PayloadAction<TechOperationAsset[]>): void {
      state.assets = action.payload;
    },
  },
  extraReducers(builder: ActionReducerMapBuilder<ITechOperationEditingState>) {
    const enableLoading = (state: Draft<ITechOperationEditingState>): void => {
      state.isLoading = true;
    };
    const disableLoading = (state: Draft<ITechOperationEditingState>): void => {
      state.isLoading = false;
    };
    builder
      .addCase(fetchTechOperationByIdAction.pending, enableLoading)
      .addCase(fetchTechOperationByIdAction.fulfilled, disableLoading)
      .addCase(fetchTechOperationByIdAction.rejected, disableLoading)
      .addCase(addTechOperationAction.pending, enableLoading)
      .addCase(addTechOperationAction.fulfilled, disableLoading)
      .addCase(addTechOperationAction.rejected, disableLoading)
      .addCase(updateTechOperationAction.pending, enableLoading)
      .addCase(updateTechOperationAction.fulfilled, disableLoading)
      .addCase(updateTechOperationAction.rejected, disableLoading);
  },
});

export const techOperationEditingReducer = techOperationEditingSlice.reducer;
export const { setTechOperationData, setTechOperationAssetTypes, setTechOperationAssets } =
  techOperationEditingSlice.actions;

export const fetchTechOperationByIdAction = createAsyncThunk<TechOperation, string>(
  `${MODULE_NAME}/fetchTechOperation`,
  async (techOperationId, { dispatch }) => {
    const dto = await techOperationsService.get(techOperationId);
    const model = new TechOperation(dto.id);
    model.updateFromDto(dto);

    dispatch(setTechOperationData(model));
    return model;
  }
);

export const addTechOperationAction = createAsyncThunk<TechOperation, TechOperation>(
  `${MODULE_NAME}/addTechOperation`,
  async (data, { dispatch }) => {
    data.resetCreatedOnClientAt();
    const dto = await techOperationsService.add(data.asDto);
    const newModel = new TechOperation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setTechOperationData(newModel));
    return newModel;
  }
);

export const updateTechOperationAction = createAsyncThunk<TechOperation, TechOperation>(
  `${MODULE_NAME}/updateTechOperation`,
  async (data, { dispatch }) => {
    const dto = await techOperationsService.update(data.asDto);
    const newModel = new TechOperation(dto.id);
    newModel.updateFromDto(dto);
    dispatch(setTechOperationData(newModel));
    return newModel;
  }
);

export const fetchAssetTypesAction = createAsyncThunk<TechOperationAssetType[], void>(
  `${MODULE_NAME}/fetchTechOperationAssetTypes`,
  async (_, { dispatch }) => {
    const dtos = await techOperationsAssetsService.typesList();
    const assetTypes = dtos.map((dto) => {
      const assetType = new TechOperationAssetType();
      assetType.updateFromDto(dto);
      return assetType;
    });
    dispatch(setTechOperationAssetTypes(assetTypes));
    return assetTypes;
  }
);

export const fetchAssetsByTechOperationIdAction = createAsyncThunk<TechOperationAsset[], string>(
  `${MODULE_NAME}/fetchTechOperationAssets`,
  async (techOperationId, { dispatch }) => {
    let assets: TechOperationAsset[] = [];
    if (isEditingPage(techOperationId)) {
      const dtos = await techOperationsAssetsService.list({ where: { techOperationId } });
      assets = dtos.map((dto) => {
        const asset = new TechOperationAsset(dto.id);
        asset.updateFromDto(dto);
        return asset;
      });
      dispatch(setTechOperationAssets(assets));
    }
    return assets;
  }
);
