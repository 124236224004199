import React, { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { DetailsLayout } from "../../components/details-layout/details-layout";
import { getDetails, getLoading } from "../../store/farm-lands.selector";
import { fetchFarmLandByIdAction, setDetailsAction } from "../../store/farm-lands.slice";
import { FarmLandBrief } from "../farm-land-brief/farm-land-brief";

const FarmLandInfo = lazy(() => import("../farm-land-info/farm-land-info"));
const FarmLandTech = lazy(() => import("../farm-land-tech/farm-land-tech"));
const FarmLandReports = lazy(
  () => import("../../../observations/containers/farm-land-observations/farm-land-observations")
);

export const FarmLandDetails = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);
  const details = useSelector(getDetails);

  // update base info
  useEffect(() => {
    if (!id) {
      return;
    }

    dispatch(fetchFarmLandByIdAction(id));

    return () => {
      dispatch(setDetailsAction(null));
    };
  }, [id, dispatch]);

  // TODO: add replace to all navigate to index rows
  return (
    <DetailsLayout submenuChildren={<FarmLandBrief model={details} isLoading={isLoading} />}>
      <Routes>
        <Route index={true} element={<FarmLandInfo />} />
        <Route path={"tech"} element={<FarmLandTech />} />
        <Route path={"reports"} element={<FarmLandReports />} />
        <Route path="*" element={<Navigate to={"."} replace={true} />} />
      </Routes>
    </DetailsLayout>
  );
};
