import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export function TractorIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path d="m16.32 3.904l-2.848 9.984h-3.776v-7.68h-1.632v7.616l-5.408 0.672 0.448 4.992h-3.008v5.28h2.08v0.352c0 2.656 2.176 4.768 4.768 4.768 2.56 0 4.768-2.08 4.768-4.768 0-0.928-0.256-1.792-0.736-2.528h5.184c0.384 4.16 3.904 7.264 7.968 7.264 4.288 0 8-3.52 8-8 0-3.584-2.336-6.528-5.472-7.584v-10.336h-10.336zm1.632 1.984h6.496v7.968h-0.352c-0.96 0-1.888 0.192-2.752 0.512l-5.568-0.448 2.176-8zm6.144 12.768c2.048 0 3.264 1.504 3.264 3.2s-1.216 3.2-3.264 3.2-3.264-1.504-3.264-3.2 1.216-3.2 3.264-3.2zm-17.184 4.16c1.408 0 2.464 1.088 2.464 2.304 0 1.248-1.024 2.304-2.464 2.304-1.408 0-2.464-1.088-2.464-2.304 0-1.248 1.024-2.304 2.464-2.304z" />
    </SvgIcon>
  );
}
