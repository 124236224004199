import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useAppDispatch } from "../../../../store";
import { setSearchText } from "../../store";
import FertilisersTab from "../fertilisers-tab/fertilisers-tab";

export const Fertilisers = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSearchText(""));
  }, [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="*" element={<FertilisersTab />} />
      </Routes>
    </Suspense>
  );
};
