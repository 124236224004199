import { Avatar, Divider, Fade, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { getUser } from "../../authentication/store/auth.selector";
import { logoutSuccess } from "../../authentication/store/auth.slice";
import { useAppDispatch } from "../../store";
import { FilterFarmAndSeason } from "../filter/shared/containers/filter-farm-and-season/filter-farm-and-season";
import { useStyles } from "./user-panel-style";

export const UserPanel = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const classes = useStyles();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const logoutClickHandler = useCallback(() => dispatch(logoutSuccess()), [dispatch]);
  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget),
    [setAnchorElUser]
  );
  const handleClose = useCallback(() => setAnchorElUser(null), [setAnchorElUser]);

  return (
    <div className={classes.root}>
      <div className={classes.userInfoRoot}>
        <div className={classes.defaultFarm}>
          <FilterFarmAndSeason />
        </div>
      </div>

      {user && (
        <>
          <Tooltip title="Открыть настройки">
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar src={user.avatarUrl || undefined} alt={user.fullName}>
                <AccountCircle className={classes.userIcon} />
              </Avatar>
            </IconButton>
          </Tooltip>

          <Menu
            style={{ inset: "42px 0px 0px" }}
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem button={false} className={classes.userTitle}>
              {/*switch to Stack*/}
              <Grid container={true} direction={"column"}>
                <Grid item={true}>{user.fullName}</Grid>
                <Grid item={true}>
                  <Typography component="div" variant="caption">
                    {user.positionName}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <Divider />
            <MenuItem
              id={"logout=menu-item"}
              selected={false}
              onClick={logoutClickHandler}
              className={classes.menuItem}
            >
              <ExitToApp className={classes.menuItemIcon} />
              Выход
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};
