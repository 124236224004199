import { format } from "date-fns";

import { HUMAN_DATE_FORMAT } from "../../../../constant";
import { ProductionType } from "../../../../shared/enums/production-type";
import { IFarmLandTechDto } from "../dtos/farm-land-tech.dto";
import { OperationGroupType } from "../enums/operation-group-type";

export class FarmLandTech {
  id: string;
  farmLandName: string;
  cropTypeId: string;
  startedAt: number; // unixtime
  finishedAt: number; // unixtime
  productionType: ProductionType;
  techOperationGroupId: OperationGroupType; // TODO: must be changed
  fieldSize: number;
  techAssetName: string;
  trailerAssetName: string;

  // TODO: check on existing of finished date
  get workPeriod(): string {
    return `${format(this.startedAt, HUMAN_DATE_FORMAT)} - ${format(this.finishedAt, HUMAN_DATE_FORMAT)}`;
  }

  get asDto(): IFarmLandTechDto {
    return {
      id: this.id,
      farmLandName: this.farmLandName,
      cropTypeId: this.cropTypeId,
      startedAt: this.startedAt,
      finishedAt: this.finishedAt,
      productionType: this.productionType,
      techOperationGroupId: this.techOperationGroupId,
      fieldSize: this.fieldSize,
      techAssetName: this.techAssetName,
      trailerAssetName: this.trailerAssetName,
    };
  }

  updateFromDto(dto: IFarmLandTechDto): void {
    this.id = dto.id;
    this.farmLandName = dto.farmLandName;
    this.cropTypeId = dto.cropTypeId;
    this.startedAt = dto.startedAt;
    this.finishedAt = dto.finishedAt;
    this.productionType = dto.productionType;
    this.techOperationGroupId = dto.techOperationGroupId as OperationGroupType;
    this.fieldSize = dto.fieldSize;
    this.techAssetName = dto.techAssetName;
    this.trailerAssetName = dto.trailerAssetName;
  }
}
