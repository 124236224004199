import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { IObservationsState } from "../shared/interfaces/observations-state";
import { Observation } from "../shared/models/observation";
import { observationsService } from "../shared/services/observations.service";

const initialState: IObservationsState = {
  isLoading: false,
  list: [],
  listCount: 0,
};

export const MODULE_NAME = "observations";
export const observationsSlice = createSlice({
  name: MODULE_NAME,
  initialState: initialState,
  reducers: {
    setListAction(state, action: PayloadAction<Observation[]>): void {
      state.list = action.payload;
    },
    setListCountAction(state, action: PayloadAction<number>): void {
      state.listCount = action.payload;
    },
    removeItemFromListAction(state, action: PayloadAction<string>): void {
      const index = state.list.findIndex((item) => item.id === action.payload);

      if (index === -1) {
        return;
      }
      state.list.splice(index, 1);
    },
    decrementListCountAction(state): void {
      state.listCount--;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchObservationsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchObservationsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchObservationsAction.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeObservationAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeObservationAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(removeObservationAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const observationsReducer = observationsSlice.reducer;
export const { setListAction, setListCountAction, removeItemFromListAction, decrementListCountAction } =
  observationsSlice.actions;

// async actions
let abortController: AbortController | null = null;
export const fetchObservationsAction = createAsyncThunk<void, ILoopbackFilter>(
  `${MODULE_NAME}/fetchObservations`,
  async (filter, { dispatch }) => {
    // prevent multiple calls
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();
    let models: Observation[];
    let count: { count: number };
    try {
      count = await observationsService.listCount(filter, { signal: abortController.signal });
      const dtos = await observationsService.list(filter, { signal: abortController.signal });
      models = dtos.map((dto) => {
        const model = new Observation(dto.id);
        model.updateFromDto(dto);
        return model;
      });
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "AbortError") {
          return;
        }
      }
      throw e;
    }
    abortController = null;
    dispatch(setListAction(models));
    // TODO: depends of optimisation needs, we could change it only if filter changes
    dispatch(setListCountAction(count.count));
  }
);

export const removeObservationAction = createAsyncThunk<void, string>(
  `${MODULE_NAME}/removeObservationAction`,
  async (id: string, { dispatch }) => {
    try {
      await observationsService.delete(id);
      dispatch(removeItemFromListAction(id));
      dispatch(decrementListCountAction());
    } catch (e) {
      throw e;
    }
  }
);
