import { TLabel } from "../../../../shared/interface";
import { IWeedDto } from "../dtos/weeds";

export class Weed {
  readonly id: string;
  aliases: string;
  name: string;
  latinName: string;
  imageUrl: string;
  family: string;
  genus: string;
  cladeClass: string;
  cladeGroup: string;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IWeedDto {
    return {
      id: this.id,
      aliases: this.aliases,
      name: this.name,
      latinName: this.latinName,
      imageUrl: this.imageUrl,
      family: this.family,
      genus: this.genus,
      cladeClass: this.cladeClass,
      cladeGroup: this.cladeGroup,
    };
  }

  updateFromDto(dto: IWeedDto): void {
    this.aliases = dto.aliases;
    this.name = dto.name;
    this.latinName = dto.latinName;
    this.imageUrl = dto.imageUrl;
    this.family = dto.family;
    this.genus = dto.genus;
    this.cladeClass = dto.cladeClass;
    this.cladeGroup = dto.cladeGroup;
  }

  get asViolationType(): TLabel {
    return {
      id: this.id,
      title: this.name,
    };
  }
}
