import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";

interface IProps {
  title: string;
  message: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const ConfirmDialog = (props: IProps): JSX.Element => {
  const { title, message, onSuccess, onClose } = props;

  const agreeHandler = () => {
    onSuccess();
    onClose();
  };

  const disagreeHandler = () => {
    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={disagreeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={disagreeHandler}>Несогласен</Button>
        <Button onClick={agreeHandler}>Согласен</Button>
      </DialogActions>
    </Dialog>
  );
};
