import { FormControlLabel, Grid, ListItem, Switch } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { IEmployeesDto } from "../../../../modules/employees/shared";
import { getList as getEmployeesList } from "../../../../modules/employees/store";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { getInfoWorkPlace } from "../../../../modules/info-data";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { isMachineOperator } from "../../../../shared/utils/is-machine-operator";

interface IProps {
  isRequired: boolean;
  isEditingTechOperation: boolean;
}

export const TechOperationEditingEmployees = (props: IProps): JSX.Element => {
  const { isRequired = false, isEditingTechOperation } = props;
  const rhfMethods = useFormContext();
  const watchFields = rhfMethods.watch();

  const appFilterSeasonId = useSelector((state) => getFilter(state, FilterName.SeasonId));
  const employeesRaw = useSelector(getEmployeesList);
  const workPlaces = useSelector(getInfoWorkPlace);

  const [employeeAllowAllFarms, setEmployeeAllowAllFarms] = useState(false);
  const [employeeAllowAllPositions, setEmployeeAllowAllPositions] = useState(false);

  useEffect(() => {
    (async () => {
      if (!watchFields.farmId || !appFilterSeasonId || !isEditingTechOperation) {
        return;
      }

      setEmployeeAllowAllFarms(true);
      setEmployeeAllowAllPositions(true);
    })();
  }, [watchFields.farmId, appFilterSeasonId, isEditingTechOperation]);

  const employees = useMemo(() => {
    return employeesRaw.map((employee) => {
      return {
        ...employee,
        workPlace: workPlaces.find((item) => {
          return item.id === employee.workPlaceId;
        }),
      };
    });
  }, [employeesRaw, workPlaces]);

  const availableEmployees = useMemo(() => {
    return employees.filter(
      (item) =>
        (employeeAllowAllFarms || item.workPlace?.farmId === watchFields.farmId) &&
        (employeeAllowAllPositions || isMachineOperator(item))
    );
  }, [employees, employeeAllowAllFarms, employeeAllowAllPositions, watchFields.farmId]);

  return (
    employees && (
      <ListItem>
        <Grid container={true} spacing={2} alignItems={"center"}>
          <Grid item={true} xs={8}>
            <RHFAutocomplete<IEmployeesDto>
              name="employeeId"
              rules={{ required: isRequired }}
              renderValue={(value) => findModelByProperty(availableEmployees, value)}
              AutocompleteProps={{
                disabled: !watchFields.farmLandId,
                options: availableEmployees,
                getOptionLabel: (option) => option.positionName + " / " + option.fullName || "",
                noOptionsText: "Механизаторов не найдено",
              }}
              TextFieldProps={{
                required: isRequired,
                label: "Механизатор",
              }}
            />
          </Grid>

          <Grid item={true}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(evt) => setEmployeeAllowAllFarms(evt.target.checked)}
                  checked={employeeAllowAllFarms}
                  color={"primary"}
                />
              }
              label="Из всех хозяйств"
            />
          </Grid>

          <Grid item={true}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(evt) => setEmployeeAllowAllPositions(evt.target.checked)}
                  checked={employeeAllowAllPositions}
                  color={"primary"}
                />
              }
              label="Все сотрудники"
            />
          </Grid>
        </Grid>
      </ListItem>
    )
  );
};
