export enum ProductionType {
  default = "default",
  feed = "feed",
  commercial = "commercial",
  byProduct = "byProduct",
}

export enum ProductionTypeHuman {
  default = "По умолчанию",
  feed = "Кормовая",
  commercial = "Товарная",
  byProduct = "Побочная",
}
