import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { logAction } from "../../errors";
import { IPhenophasesState, PhenophaseModel, phenophasesService } from "../shared";

const initialState: IPhenophasesState = {
  isLoading: false,
  list: [],
  selectedIds: [],
  searchText: "",
};
export const MODULE_NAME = "phenophases";
export const phenophasesSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<PhenophaseModel[]>): void {
      state.list = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPhenophasesAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchPhenophasesAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPhenophasesAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const phenophasesReducer = phenophasesSlice.reducer;
export const { setListAction, setSelectedIdsAction, toggleId, setSearchText } = phenophasesSlice.actions;

export const fetchPhenophasesAction = createAsyncThunk(
  `${MODULE_NAME}/fetchPhenophases`,
  async (params, { dispatch }) => {
    const dtos = await phenophasesService.list({});
    const models = dtos.map((dto) => {
      const model = new PhenophaseModel(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
  }
);

export const deletePhenophasesAction = createAsyncThunk<Promise<void>, string[]>(
  `${MODULE_NAME}/deleteCropsAction`,
  async (ids, { dispatch }) => {
    const selectedToDeletePromises = ids.map((id) => phenophasesService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      dispatch(logAction("Something went wrong"));
    }
  }
);
