import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { LoginLayout } from "../../components/login-layout/login-layout";
import { LoginForm } from "../login-form/login-form";
import { RestorePassword } from "../restore-password/restore-password";

export const Login = (): JSX.Element => (
  <Routes>
    <Route path={"*"} element={<LoginLayout />}>
      <Route index={true} element={<LoginForm />} />
      <Route path={"restore-password"} element={<RestorePassword />} />
      <Route path={"*"} element={<Navigate to={"."} />} />
    </Route>
  </Routes>
);
