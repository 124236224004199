import { createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { MODULE_NAME as AUTH_MODULE_NAME, authSliceReducer } from "./authentication/store/auth.slice";
import { APP_USERS_MODULE_NAME, appUsersReducer, fetchAppUsersAction } from "./modules/app-users";
import { CHEMICALS_MODULE_NAME, chemicalsReducer, fetchChemicalsAction } from "./modules/chemicals";
import { MODULE_NAME as CROPS_MODULE_NAME, cropsReducer, fetchCropsAction } from "./modules/crop";
import { CROP_TYPES_MODULE_NAME, cropTypesReducer, fetchCropTypesAction } from "./modules/crop-types";
import { DISEASES_MODULE_NAME, diseasesReducer, fetchDiseasesAction } from "./modules/diseases";
import { EMPLOYEES_MODULE_NAME, employeesReducer, fetchEmployeesAction } from "./modules/employees";
import { MODULE_NAME as ERRORS_MODULE_NAME, errorsReducer, logAction } from "./modules/errors/store";
import { FARMS_MODULE_NAME, farmsReducer } from "./modules/farms";
import { FERTILISERS_MODULE_NAME, fertilisersReducer, fetchFertilisersAction } from "./modules/fertilisers";
import { MODULE_NAME as FILTERS_MODULE_NAME, fetchFiltersAction, filtersReducer } from "./modules/filter";
import { INFO_DATA_KEY, fetchCatalogInfoData, infoDataReducer } from "./modules/info-data";
import {
  MODULE_NAME as NOTIFICATIONS_MODULE_NAME,
  notificationsReducer,
} from "./modules/notifications/store/notifications.slice";
import { PESTS_MODULE_NAME, fetchPestsAction, pestsReducer } from "./modules/pests";
import { PHENOPHASES_MODULE_NAME, fetchPhenophasesAction, phenophasesReducer } from "./modules/phenophases";
import { SEEDS_MODULE_NAME, fetchSeedsAction, seedsReducer } from "./modules/seeds";
import { TECH_ASSETS_MODULE_NAME, fetchTechAssetsAction, techAssetsReducer } from "./modules/tech-assets";
import { WEEDS_MODULE_NAME, fetchWeedsAction, weedsReducer } from "./modules/weeds";
import {
  MODULE_NAME as CROP_ROTATION_MODULE_NAME,
  cropRotationReducer,
} from "./pages/fields/store/crop-rotation.slice";
import {
  MODULE_NAME as FARM_LANDS_TECH_MODULE_NAME,
  farmLandsTechReducer,
} from "./pages/fields/store/farm-lands-tech.slice";
import {
  MODULE_NAME as FARM_LANDS_WEATHER_MODULE_NAME,
  farmLandsWeatherReducer,
} from "./pages/fields/store/farm-lands-weather.slice";
import { MODULE_NAME as FIELDS_MODULE_NAME, farmLandsReducer } from "./pages/fields/store/farm-lands.slice";
import {
  MAP_CONTROLS_MODULE_NAME,
  MODULE_NAME as MAP_PAGE_MODULE_NAME,
  mapControlsReducer,
  mapReducer,
} from "./pages/map-page";
import {
  MODULE_NAME as OBSERVATION_EDITING_MODULE_NAME,
  observationEditingReducer,
} from "./pages/observations/store/observation-editing.slice";
import {
  MODULE_NAME as OBSERVATIONS_MODULE_NAME,
  observationsReducer,
} from "./pages/observations/store/observations.slice";
import { TECHOPERATIONS_MODULE_NAME, techOperationsReducer } from "./pages/tech-operations";
import {
  MODULE_NAME as TECHOPERATION_EDITING_MODULE_NAME,
  techOperationEditingReducer,
} from "./pages/tech-operations/store/tech-operation-editing.slice";
import { USE_ROLE_KEY } from "./utils/hooks/use-role/use-role-actions";
import { useRoleReducer } from "./utils/hooks/use-role/use-role-reducer";

export const dynamicReducers = {
  [AUTH_MODULE_NAME]: authSliceReducer,
  [INFO_DATA_KEY]: infoDataReducer,
  [APP_USERS_MODULE_NAME]: appUsersReducer,
  [CHEMICALS_MODULE_NAME]: chemicalsReducer,
  [CROP_ROTATION_MODULE_NAME]: cropRotationReducer,
  [CROP_TYPES_MODULE_NAME]: cropTypesReducer,
  [DISEASES_MODULE_NAME]: diseasesReducer,
  [EMPLOYEES_MODULE_NAME]: employeesReducer,
  [FARMS_MODULE_NAME]: farmsReducer,
  [FIELDS_MODULE_NAME]: farmLandsReducer,
  [FARM_LANDS_TECH_MODULE_NAME]: farmLandsTechReducer,
  [FARM_LANDS_WEATHER_MODULE_NAME]: farmLandsWeatherReducer,
  [FERTILISERS_MODULE_NAME]: fertilisersReducer,
  [MAP_CONTROLS_MODULE_NAME]: mapControlsReducer,
  [MAP_PAGE_MODULE_NAME]: mapReducer,
  [OBSERVATIONS_MODULE_NAME]: observationsReducer,
  [OBSERVATION_EDITING_MODULE_NAME]: observationEditingReducer,
  [PESTS_MODULE_NAME]: pestsReducer,
  [PHENOPHASES_MODULE_NAME]: phenophasesReducer,
  [SEEDS_MODULE_NAME]: seedsReducer,
  [TECH_ASSETS_MODULE_NAME]: techAssetsReducer,
  [TECHOPERATIONS_MODULE_NAME]: techOperationsReducer,
  [TECHOPERATION_EDITING_MODULE_NAME]: techOperationEditingReducer,
  [USE_ROLE_KEY]: useRoleReducer,
  [CROPS_MODULE_NAME]: cropsReducer,
  [FILTERS_MODULE_NAME]: filtersReducer,
  [WEEDS_MODULE_NAME]: weedsReducer,
  [ERRORS_MODULE_NAME]: errorsReducer,
  [NOTIFICATIONS_MODULE_NAME]: notificationsReducer,
};

// TODO: remove it later
export const reducers = combineReducers(dynamicReducers);

export const loadDictionariesAction = createAsyncThunk<Promise<void>, void>(
  `GLOBAL/loadDictionaries`,
  async (_, { dispatch }) => {
    const key = "isDictionariesLoaded";
    const isLoaded = sessionStorage.getItem(key);

    if (isLoaded) {
      return Promise.resolve();
    }

    try {
      await Promise.all([
        dispatch(fetchCatalogInfoData()),
        dispatch(fetchCropTypesAction()),
        dispatch(fetchFiltersAction()),
        // ** Catalogs **
        dispatch(fetchTechAssetsAction()),
        dispatch(fetchEmployeesAction()),
        dispatch(fetchAppUsersAction()),
        dispatch(fetchChemicalsAction()),
        dispatch(fetchFertilisersAction()),
        dispatch(fetchSeedsAction()),
        dispatch(fetchCropsAction()),
        dispatch(fetchDiseasesAction()),
        dispatch(fetchWeedsAction()),
        dispatch(fetchPestsAction()),
        dispatch(fetchPhenophasesAction()),
      ]);
    } catch (e) {
      dispatch(logAction("Dictionaries haven't been loaded"));
    }
  }
);
