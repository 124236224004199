import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IObservationEditingState } from "../shared/interfaces/observation-editing-state";
import { MODULE_NAME } from "./observation-editing.slice";

//eslint-disable-next-line
export const getObservationEditingState = (rootState: any): IObservationEditingState => rootState[MODULE_NAME];

export const getIsLoading = createDraftSafeSelector(
  getObservationEditingState,
  (state: IObservationEditingState) => state.isLoading
);

export const getOldPhotosList = createDraftSafeSelector(
  getObservationEditingState,
  (state: IObservationEditingState) => state.oldPhotos
);

export const getChosenPhotosList = createDraftSafeSelector(
  getObservationEditingState,
  (state: IObservationEditingState) => state.chosenPhotos
);
