import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import {
  deleteDiseasesAction,
  fetchDiseasesAction,
  getSelectedIds,
  setSearchText,
  setSelectedIdsAction,
} from "../../store";

const DiseaseList = lazy(() => import("../diseases-list/diseases-list"));

const DiseasesTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedIds = useSelector(getSelectedIds);

  const onDelete = useCallback(
    () => dispatch(deleteDiseasesAction(selectedIds)).then(() => dispatch(fetchDiseasesAction())),
    [selectedIds, dispatch]
  );
  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.DISEASES}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <DiseaseList />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default DiseasesTab;
