import React, { useCallback, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { useAppDispatch } from "../../../../store";
import { TechOperationAssetsGroup } from "../../components/tech-operation-editing-assets-group/tech-operation-editing-assets-group";
import { ITechOperationSubGroupDto } from "../../shared/dtos/tech-operation-sub-group.dto";
import { TechOperationAssetEntityID } from "../../shared/enums/tech-operation-asset-entity";
import { ITechOperationAssetFormData } from "../../shared/interfaces/tech-operation-asset-form-data";
import { TechOperationAsset } from "../../shared/models/tech-operation-asset";
import { fetchAssetTypesAction, fetchAssetsByTechOperationIdAction } from "../../store/tech-operation-editing.slice";

interface IProps {
  techOperationId?: string;
  techOperationSubGroup: ITechOperationSubGroupDto | null; // don't use "any" when you KNOW the type
}

export const TechOperationEditingAssets = ({ techOperationId, techOperationSubGroup }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const rhfMethods = useFormContext();

  const { fields, append, remove } = useFieldArray<ITechOperationAssetFormData>({
    name: "assets",
    control: rhfMethods.control,
  });

  useEffect(() => {
    dispatch(fetchAssetTypesAction());
    if (!techOperationId) {
      return;
    }
    dispatch(fetchAssetsByTechOperationIdAction(techOperationId));
  }, [dispatch, techOperationId]);

  const assetAppend = useCallback(
    (entityType) => {
      const newAsset = new TechOperationAsset().setEntityId(entityType).asFormData;
      append(newAsset);
    },
    [append]
  );

  useEffect(() => {
    if (!techOperationSubGroup) {
      return;
    }

    if (techOperationSubGroup.usesChemicals) {
      const alreadyAppended = fields.filter((x) => x.entityId === TechOperationAssetEntityID.CHEMICAL);
      if (alreadyAppended.length === 0) {
        assetAppend(TechOperationAssetEntityID.CHEMICAL);
      }
    }

    if (techOperationSubGroup.usesFertilisers) {
      const alreadyAppended = fields.filter((x) => x.entityId === TechOperationAssetEntityID.FERTILISER);
      if (alreadyAppended.length === 0) {
        assetAppend(TechOperationAssetEntityID.FERTILISER);
      }
    }

    if (techOperationSubGroup.usesSeeds) {
      const alreadyAppended = fields.filter((x) => x.entityId === TechOperationAssetEntityID.SEED);
      if (alreadyAppended.length === 0) {
        assetAppend(TechOperationAssetEntityID.SEED);
      }
    }
  }, [techOperationSubGroup, assetAppend, fields]);

  if (!techOperationSubGroup) {
    return <></>;
  }

  // no need to useMemo() for a prop; when a prop changes:
  // 1) this component will be re-invoked 100%;
  // 2) memoization will be invoked in 100% cases => no need to allocate extra resources;
  const isAssetRequired =
    techOperationSubGroup.usesChemicals || techOperationSubGroup.usesFertilisers || techOperationSubGroup.usesSeeds;

  if (!isAssetRequired) {
    return <></>;
  }

  return (
    <>
      {techOperationSubGroup.usesChemicals && (
        <TechOperationAssetsGroup
          title="СЗР и норма внесения на гектар"
          optionsLabel="Тип вносимых СЗР"
          noOptionsText="Нет доступных СЗР"
          addButtonText="Добавить СЗР"
          entityType={TechOperationAssetEntityID.CHEMICAL}
          assetsList={fields}
          onAssetAppend={assetAppend}
          onAssetRemove={remove}
          sortOptions={true}
          sortUnits={true}
        />
      )}

      {techOperationSubGroup.usesFertilisers && (
        <TechOperationAssetsGroup
          title="Удобрения и норма внесения на гектар"
          optionsLabel="Тип вносимых удобрений"
          noOptionsText="Нет доступных удобрений"
          addButtonText="Добавить удобрения"
          entityType={TechOperationAssetEntityID.FERTILISER}
          assetsList={fields}
          onAssetAppend={assetAppend}
          onAssetRemove={remove}
          sortOptions={true}
          sortUnits={true}
        />
      )}

      {techOperationSubGroup.usesSeeds && (
        <TechOperationAssetsGroup
          title="Семена"
          optionsLabel="Тип используемых семян"
          noOptionsText="Нет доступных семян"
          addButtonText="Добавить семена"
          entityType={TechOperationAssetEntityID.SEED}
          assetsList={fields}
          onAssetAppend={assetAppend}
          onAssetRemove={remove}
          sortOptions={true}
          sortUnits={true}
        />
      )}
    </>
  );
};
