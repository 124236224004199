import { API_URL } from "../config/api";

/**
 * FIND all instances of the model / a model instance by {{id}} matched by filter from the data source.
 * @param {string} endpoints endpoints request api
 * @param {string} id Optional. Model id
 * @param {string} part Optional.
 * @param {number} limit Optional.
 * @param {string[]} fields Optional. ["id", "name", "seasonId"]
 * @param {string[]} includes Optional. ["crop", "appUser", "farm"]
 * @param {any} where Optional. {
        and: [
          { inputDate: { gt: ... } },
          { inputDate: { lt: ... } },
          { seasonId: ... },
        ],
      }
 */

//eslint-disable-next-line
const getFeaturesAPI = async (
  endpoints: string,
  id?: string,
  part?: string,
  limit?: number,
  fields?: string[],
  includes?: { [key: string]: string[] }[],
  //eslint-disable-next-line
  where?: any
) => {
  const ACCESS_TOKEN = localStorage["access_token"];
  if (!ACCESS_TOKEN) return;

  let url = `${API_URL}/${endpoints}`;
  if (id) url = url + `/${id}`;
  if (part) url = url + `/${part}`;
  url = url + `?access_token=${ACCESS_TOKEN}`;

  const filter = {};

  if (limit) filter["limit"] = limit;
  if (includes)
    filter["include"] = includes.map((item) => {
      const keyName = Object.keys(item)[0];
      return {
        relation: keyName,
        scope: { fields: item[keyName] },
      };
    });
  if (fields) filter["fields"] = fields;
  if (where) filter["where"] = where;

  url = url + `&filter=${JSON.stringify(filter)}`;

  const responseURL = new URL(url);

  try {
    const response = await fetch(responseURL.toString(), {});
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch {
    // eslint-disable-next-line no-console
    console.log(`Ошибка загрузки данных.`);
    return;
  }
};

/**
 * PATCH attributes for a model instance and persist it into the data source.
 * @param {string} endpoints endpoints request api
 * @param {string} id model id
 * @param {object} data model instance data
 */

//eslint-disable-next-line
const patchFeatureAPI = async (endpoints: string, id: string, data: object) => {
  const ACCESS_TOKEN = localStorage["access_token"];

  const url = new URL(`${API_URL}/${endpoints}/${id}?access_token=${ACCESS_TOKEN}`);

  try {
    const response = await fetch(url.toString(), {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch {
    // eslint-disable-next-line no-console
    console.log(`Ошибка редактирования данных.`);
    return;
  }
};

/**
 * CREATE a new instance of the model and persist it into the data source.
 * @param {string} endpoints endpoints request api
 * @param {object} data model instance data
 */

//eslint-disable-next-line
const postFeatureAPI = async (endpoints: string, data: object) => {
  const ACCESS_TOKEN = localStorage["access_token"];

  const url = new URL(`${API_URL}/${endpoints}?access_token=${ACCESS_TOKEN}`);

  try {
    const response = await fetch(url.toString(), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch {
    // eslint-disable-next-line no-console
    console.log(`Ошибка создания объекта.`);
    return;
  }
};

/**
 * DELETE a model instance by {{id}} from the data source.
 * @param {string} endpoints endpoints request api
 * @param {string} id model id
 */

//eslint-disable-next-line
const deleteFeatureAPI = async (endpoints: string, id: string) => {
  const ACCESS_TOKEN = localStorage["access_token"];

  const url = new URL(`${API_URL}/${endpoints}/${id}?access_token=${ACCESS_TOKEN}`);

  try {
    const response = await fetch(url.toString(), {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });

    const jsonResponse = await response.json();
    return jsonResponse.count === 0 ? false : true;
  } catch {
    // eslint-disable-next-line no-console
    console.log(`Объект не удалось удалить.`);
    return;
  }
};

export class Util {
  static getFeaturesAPI = getFeaturesAPI;
  static patchFeatureAPI = patchFeatureAPI;
  static postFeatureAPI = postFeatureAPI;
  static deleteFeatureAPI = deleteFeatureAPI;
}
