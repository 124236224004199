import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { TLabel } from "../../../shared/interface";
import { ICropAggregationDto, ICropAggregationFarmLandDto } from "../shared/dtos/crop-aggregation.dto";
import { IMapControlsState } from "../shared/interfaces/map-controls-state";
import { MODULE_NAME } from "./map-controls.slice";

// eslint-disable-next-line
export const getMapControlsState = (appState: any): IMapControlsState => appState[MODULE_NAME];

export const getIsLoading = createDraftSafeSelector(getMapControlsState, (state: IMapControlsState) => state.isLoading);

export const getList = createDraftSafeSelector(getMapControlsState, (state: IMapControlsState) => state.list);

export const getSelected = createDraftSafeSelector(getMapControlsState, (state: IMapControlsState) => state.selected);

export const getOpened = createDraftSafeSelector(getMapControlsState, (state: IMapControlsState) => state.opened);

export const getSelectedFarmLands = createDraftSafeSelector(
  getMapControlsState,
  (state: IMapControlsState) => state.selectedFarmLands
);

export const getSearchFarmLandName = createDraftSafeSelector(
  getMapControlsState,
  (state: IMapControlsState) => state.searchFarmLandName
);

export const getFilteredList = createDraftSafeSelector(getMapControlsState, (state: IMapControlsState) => {
  const searchName = state.searchFarmLandName;
  return state.list
    .map((aggregation) => {
      const farmLands = aggregation.farmLands.filter((item) => item.farmLandName.includes(searchName));
      return { ...aggregation, farmLands };
    })
    .filter((aggregation) => !!aggregation.farmLands.length);
});

export const getFarmLandNameSearchOptions = createDraftSafeSelector(getFilteredList, (list: ICropAggregationDto[]) => {
  return list
    .reduce((acc, cropType) => acc.concat(cropType.farmLands), [] as ICropAggregationFarmLandDto[])
    .map(
      (farmLand: ICropAggregationFarmLandDto): TLabel => ({
        id: farmLand.arcgisObjectId.toString(),
        title: farmLand.farmLandName,
      })
    )
    .sort((a, b) => (a.title > b.title ? 1 : -1));
});

export const getSearchedNames = createDraftSafeSelector(getMapControlsState, (state: IMapControlsState) => {
  const match = state.searchFarmLandName;

  if (!match) {
    return [];
  }

  return state.list
    .reduce((acc, cropType) => acc.concat(cropType.farmLands), [] as ICropAggregationFarmLandDto[])
    .filter((item: ICropAggregationFarmLandDto) => item.farmLandName.includes(match))
    .map((item) => item.farmLandName);
});
