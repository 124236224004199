import { ILoopbackFilter } from "../../../../modules/filter/shared/interfaces/loopback";
import { BaseService } from "../../../../shared/services";
import { IObservationPhotoDto } from "../dtos/observation-photo.dto";
import { IObservationDto } from "../dtos/observations.dto";
import { observationDtoToObservationUpdateDto } from "../utils/observation-dto-to-observation-update-dto";

class ObservationsService extends BaseService<IObservationDto, ILoopbackFilter> {
  private path = "ObservationsV2";
  private photosPath = "ObservationPhotos";

  async get(id: string): Promise<IObservationDto> {
    return this.fetch([this.path, id].join("/"), {
      method: "GET",
      searchParams: { filter: this.normalizeFilter({}) },
    }).then(this.filterIsDeletedPhotos);
  }

  async list(filter: ILoopbackFilter, options: { signal?: AbortSignal } = {}): Promise<IObservationDto[]> {
    const { signal = null } = options;
    return this.fetch<IObservationDto[]>(this.path, {
      method: "GET",
      searchParams: { filter: this.normalizeFilter(filter) },
      signal,
    }).then((dtos) => dtos.map(this.filterIsDeletedPhotos));
  }

  async listCount(filter: ILoopbackFilter, options: { signal?: AbortSignal } = {}): Promise<{ count: number }> {
    const { signal = null } = options;
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: filter as Record<string, unknown>,
      signal,
    });
  }

  async add(dto: IObservationDto): Promise<IObservationDto> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(observationDtoToObservationUpdateDto(dto)),
    });
  }

  async update(data: IObservationDto): Promise<IObservationDto> {
    if (!data?.id) {
      throw new Error(`Can't update entity without id`);
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(observationDtoToObservationUpdateDto(data)),
    });
  }

  // TODO: verify the possible response and add default Error interface
  async delete(id: string): Promise<unknown> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  normalizeFilter(filter: ILoopbackFilter): ILoopbackFilter {
    return {
      include: [
        { relation: "appUser", scope: { fields: ["fullName"] } },
        { relation: "crop", scope: { fields: ["name"] } },
        { relation: "farm", scope: { fields: ["name"] } },
        { relation: "farmLand", scope: { fields: ["name"] } },
        { relation: "phenoPhase", scope: { fields: ["name"] } },
        {
          relation: "observationPhotos",
          scope: {
            fields: ["id", "s3Filename", "s3FileExtension", "isDeleted"],
          },
        },
      ],
      ...filter,
    };
  }

  // TODO: check return
  async uploadPhotos(observationId: string, files: File[]): Promise<IObservationPhotoDto[]> {
    const data = new FormData();
    files.forEach((file) => {
      data.append("file", file);
    });

    return this.fetch([this.path, observationId, "uploadPhotos"].join("/"), {
      method: "POST",
      body: data,
    });
  }

  // TODO: check return
  async deletePhoto(id: string): Promise<void> {
    return this.fetch([this.photosPath, id].join("/"), {
      method: "DELETE",
    });
  }

  private filterIsDeletedPhotos(dto: IObservationDto): IObservationDto {
    return {
      ...dto,
      observationPhotos: dto.observationPhotos?.filter((photoDto) => !photoDto.isDeleted),
    };
  }
}

export const observationsService = new ObservationsService();
