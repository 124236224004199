import { IFilter } from "../../../../shared/interface/filter";
import { BaseService } from "../../../../shared/services/base.service";
import { ICropTypeDto } from "../dtos/crop-type";

class CropTypesService extends BaseService<ICropTypeDto, Partial<IFilter>> {
  private path = "CropTypes";

  async get(id: string): Promise<ICropTypeDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  async list(): Promise<ICropTypeDto[]> {
    return this.fetch(this.path, {
      method: "GET",
    });
  }
}

export const cropTypesService = new CropTypesService();
