import { Box, Grid, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { observationPhotoUrl } from "../../../../shared/utils/observation-photo-url";
import { getChosenPhotosList, getOldPhotosList } from "../../store/observation-editing.selector";
import {
  addChosenPhotosAction,
  clearPhotosListsAction,
  deleteChosenPhotoAction,
  markPhotoForDeleteAction,
} from "../../store/observation-editing.slice";
import { PhotoUploadPreview } from "../observation-photos-upload-preview/observation-photos-upload-preview";
import { useStyles } from "./observation-photos-upload.style";

export const ObservationPhotosUpload = (): JSX.Element => {
  const dispatch = useDispatch();
  const oldPhotos = useSelector(getOldPhotosList);
  const chosenPhotos = useSelector(getChosenPhotosList);
  const [currentChosenPhotosIdx, setCurrentChosenPhotosIdx] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    return () => {
      dispatch(clearPhotosListsAction());
    };
  }, [dispatch]);

  const onPhotoChoose = useCallback(
    (data) => {
      dispatch(addChosenPhotosAction(data.slice(currentChosenPhotosIdx)));
      setCurrentChosenPhotosIdx(data.length);
    },
    [dispatch, currentChosenPhotosIdx]
  );

  const onOldPhotoDelete = useCallback(
    (id) => {
      dispatch(markPhotoForDeleteAction(id));
    },
    [dispatch]
  );

  const onChosenPhotoDelete = useCallback(
    (idx) => {
      dispatch(deleteChosenPhotoAction(idx));
    },
    [dispatch]
  );

  const getDropRejectMessage = useCallback((file) => `Не удалось выбрать файл ${file.name}`, []);

  return (
    <>
      <DropzoneArea
        onChange={onPhotoChoose}
        filesLimit={9999}
        acceptedFiles={["image/jpeg"]}
        maxFileSize={99999999}
        dropzoneText={
          "Перетащите фотографии отчета сюда, либо нажмите для выбора файлов\r\n (разрешенные форматы .jpeg, .jpg)"
        }
        getDropRejectMessage={getDropRejectMessage}
        dropzoneParagraphClass={classes.dropzoneParagraph}
        showAlerts={["error"]}
        showPreviews={false}
        showPreviewsInDropzone={false}
      />

      {Boolean(oldPhotos.length || chosenPhotos.length) && (
        <>
          <Box py={2}>
            <Typography variant={"h5"}>Загруженные фотографии</Typography>
          </Box>

          <Grid container={true} spacing={4}>
            {oldPhotos.map((photo) => (
              <PhotoUploadPreview
                key={photo.id}
                isMarkedForDelete={photo.toDelete}
                src={observationPhotoUrl(photo, "@h200")}
                onDelete={() => onOldPhotoDelete(photo.id)}
              />
            ))}

            {chosenPhotos.map((photo, idx) => (
              <PhotoUploadPreview
                key={idx}
                src={window.URL.createObjectURL(photo)}
                onDelete={() => onChosenPhotoDelete(idx)}
              />
            ))}
          </Grid>
        </>
      )}
    </>
  );
};
