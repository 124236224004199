import { useCallback, useEffect, useMemo, useState } from "react";

//eslint-disable-next-line
export function useSelected() {
  const [selected, setSelected] = useState<string[]>([]);
  const callbacksMap = useMemo(() => new Map<string, (_) => void>(), []);

  const getSelectionHandler = useCallback(
    (id: string) => {
      if (!callbacksMap.has(id)) {
        callbacksMap.set(id, (_) => {
          setSelected((state) => {
            if (state.includes(id)) {
              state.splice(state.indexOf(id), 1);
            } else {
              state.push(id);
            }
            return [...state];
          });
        });
      }
      return callbacksMap.get(id);
    },
    //eslint-disable-next-line
    [setSelected]
  );

  useEffect(() => {
    return () => callbacksMap.clear();
    //eslint-disable-next-line
  }, []);

  return {
    selected,
    setSelected,
    getSelectionHandler,
  };
}
