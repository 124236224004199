import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useAppDispatch } from "../../../../store";
import { setSearchText } from "../../store";
import WeedsTab from "../weeds-tab/weeds-tab";

export const Weeds = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSearchText(""));
  }, [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="*" element={<WeedsTab />} />
      </Routes>
    </Suspense>
  );
};
