import { SET_USER_ROLE } from "./use-role-actions";

export const USE_ROLE_INITIAL_STATE = {
  roleName: undefined,
};

//eslint-disable-next-line
export const useRoleReducer = (state = USE_ROLE_INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_ROLE:
      return {
        ...state,
        roleName: payload,
      };
    default:
      return state;
  }
};
