export enum ObservationViolationType {
  Weed = "Weed",
  Disease = "Disease",
  Pest = "Pest",
}

export enum ObservationViolationTypeHuman {
  Weed = "Засоренность",
  Disease = "Болезни",
  Pest = "Вредители",
  default = "Нерациональное использование техники",
}
