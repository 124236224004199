import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch } from "../../../../store";
import { fetchFarmLandIdByArcGisIdAction } from "../../store/farm-lands.slice";

export const FarmLandsArcgisidRedirect = (): JSX.Element => {
  const { id: arcgisId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!arcgisId) {
      return;
    }
    dispatch(fetchFarmLandIdByArcGisIdAction(arcgisId))
      .unwrap()
      .then((id) => {
        if (id) {
          navigate(`../${id}`, { replace: true });
        } else {
          navigate(-1);
        }
      });
    // eslint-disable-next-line
  }, [arcgisId]);

  return <>Loading...</>;
};
