import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IPestsState } from "../shared";
import { MODULE_NAME } from "./pests.slice";

// eslint-disable-next-line
export const getPestsState = (rootState: any): IPestsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getPestsState, (state: IPestsState) => state.isLoading);
export const getList = createDraftSafeSelector(getPestsState, (state: IPestsState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getPestsState, (state: IPestsState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getPestsState, (state: IPestsState) => state.searchText);
