import { styled } from "@material-ui/core";

/*
 * TODO: add Stack component as base (instead of div) after mui5 will be implemented
 *  <Stack sx={{ width: '100%' }} spacing={2}>
 */
export const AlertWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  zIndex: 9999,
  margin: theme.spacing(1),
  left: "50%",
  transform: "translateX(-50%)",
  "& > *": {
    margin: theme.spacing(0.2),
  },
}));
