import React, { PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../store";
import { getAuthState } from "../../store/auth.selector";
import { getCurrentUser, logoutSuccess } from "../../store/auth.slice";

interface IProps {
  redirectPath?: string;
}

export const ProtectedRoute = (props: PropsWithChildren<IProps>): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectPath = "/login", children } = props;
  const { isAuthenticated, isLoading, error } = useSelector(getAuthState);
  const dispatch = useAppDispatch();

  // authentication check should be passed before rendering
  useMemo(() => {
    if (isAuthenticated || isLoading) {
      return;
    }

    dispatch(getCurrentUser())
      .unwrap()
      .then(() => navigate(location.pathname, { replace: true }))
      .catch(() => {
        dispatch(logoutSuccess);
        navigate(redirectPath, { replace: true });
      });
  }, [isAuthenticated, isLoading, dispatch, location.pathname, navigate, redirectPath]);

  if (isLoading || !isAuthenticated) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return children ? <>children</> : <Outlet />;
};
