import { IPhenophaseDto } from "../dtos/phenophases";

export class Phenophase {
  readonly id: string;
  cropTypeId: string;
  name: string;
  number: number;
  bbsc: string;
  description: string;
  minDays: number;
  maxDays: number;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IPhenophaseDto {
    return {
      id: this.id,
      cropTypeId: this.cropTypeId,
      name: this.name,
      number: this.number,
      bbsc: this.bbsc,
      description: this.description,
      minDays: this.minDays,
      maxDays: this.maxDays,
    };
  }

  updateFromDto(dto: IPhenophaseDto): void {
    this.name = dto.name;
    this.cropTypeId = dto.cropTypeId;
    this.name = dto.name;
    this.number = dto.number;
    this.bbsc = dto.bbsc;
    this.description = dto.description;
    this.minDays = dto.minDays;
    this.maxDays = dto.maxDays;
  }
}
