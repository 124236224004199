import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import {
  deletePhenophasesAction,
  fetchPhenophasesAction,
  getSelectedIds,
  setSearchText,
  setSelectedIdsAction,
} from "../../store";

const PhenophaseList = lazy(() => import("../phenophases-list/phenophases-list"));

const PhenophasesTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedIds = useSelector(getSelectedIds);

  const onDelete = useCallback(
    () => dispatch(deletePhenophasesAction(selectedIds)).then(() => dispatch(fetchPhenophasesAction())),
    [selectedIds, dispatch]
  );

  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.PHENOPHASE}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <PhenophaseList />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default PhenophasesTab;
