import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import {
  deleteChemicalsAction,
  fetchChemicalsAction,
  getSelectedIds,
  setSearchText,
  setSelectedIdsAction,
} from "../../store";

const ChemicalsTable = lazy(() => import("../chemicals-table/chemicals-table"));

const ChemicalsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const selectedIds = useSelector(getSelectedIds);
  const onDelete = useCallback(
    () => dispatch(deleteChemicalsAction(selectedIds)).then(() => dispatch(fetchChemicalsAction())),
    [selectedIds, dispatch]
  );
  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.SZR}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <ChemicalsTable />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default ChemicalsTab;
