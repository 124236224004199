export enum FarmLandCropsOutField {
  ArcgisObjectId = "GDB_ARCHIVE_OID",
  Season = "season",
  Farm = "farm",
  Name = "name",
  NameShort = "name_short",
  Number = "number",
  RegId = "reg_id",
  DistrId = "distr_id",
  SpId = "sp_id",
  FieldId = "field_id",
  Plot = "plot",
  Area2d = "area_2d",
  Crop = "crop",
  CropShortName = "crop_shortname",
  Sort = "sort",
  Reproduction = "reproduction",
  SeedsOrig = "seeds_orig",
  ProductsPurp = "products_purp",
  CultTech = "cult_tech",
  Age = "age",
  Comment = "comment",
  Note = "note",
  CreatedUser = "created_user",
  CreatedDate = "created_date",
  LastEditedUser = "last_edited_user",
  LastEditedDate = "last_edited_date",
  GuidName = "guid_name",
  Shape = "Shape",
  ObjectId = "OBJECTID",
  Score = "score",
}
