import { IObservationFormData } from "../interfaces/observation-form-data";
import { Observation } from "../models/observation";

export function updateObservationFromFormData(model: Observation, data: IObservationFormData): void {
  model.setFarmId(data.farmId);
  model.setFarmLandId(data.farmLandId);
  model.setPhenoPhaseId(data.phenoPhaseId);
  model.setCropTypeId(data.cropTypeId);
  model.setSeasonId(data.seasonId);
  model.setComment(data.comment);
  model.setViolations(data.violations);
  model.setObservationDate(data.observationDate);
}
