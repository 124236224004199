import { v4 as uuidv4 } from "uuid";

import { ITechOperationAssetTypeDto } from "../dtos/tech-operation-assetType.dto";
import { TechOperationAssetEntityID } from "../enums/tech-operation-asset-entity";

export class TechOperationAssetType {
  readonly id: string;
  entityType: TechOperationAssetEntityID;
  entityId: string;
  name: string;
  units: string[];
  unit: string;

  rangeName?: string;
  categoryName?: string;
  rate?: number;
  importRangeId?: null;
  importRangeGuid?: null;
  importUnitId?: null;
  importUnitGuid?: null;
  importId?: null;
  externalGuid?: null;
  deletedAt?: number; // timestamp
  isDeleted?: boolean;
  createdAt?: number; // timestamp
  updatedAt?: number; // timestamp
  createdByUserId?: string;
  updatedByUserId?: string;

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  get asDto(): ITechOperationAssetTypeDto {
    return {
      id: this.id,
      entityType: this.entityType,
      entityId: this.entityId,
      name: this.name,
      units: this.units,
      unit: this.unit,
    };
  }

  updateFromDto(dto: ITechOperationAssetTypeDto): void {
    this.entityType = dto.entityType;
    this.entityId = dto.entityId;
    this.name = dto.name;
    this.units = dto.units;
    this.unit = dto.unit;
  }
}
