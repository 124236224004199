import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { IFarmLandDto } from "../shared/dtos/farm-land.dto";
import { IFarmLandsState } from "../shared/interfaces/farm-lands-state";
import { FarmLand } from "../shared/models/farm-land";
import { farmLandsService } from "../shared/services/farm-lands.service";

const initialState: IFarmLandsState = {
  item: null,
  isLoading: false,
  list: [],
  listCount: 0,
};
export const MODULE_NAME = "farmLands";
export const farmLandsSlice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<FarmLand[]>): void {
      state.list = action.payload;
    },
    setListCountAction(state, action: PayloadAction<number>): void {
      state.listCount = action.payload;
    },
    setDetailsAction(state, action: PayloadAction<FarmLand | null>): void {
      state.item = action.payload;
    },
    clearDetailsAction(state): void {
      state.item = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFarmLandsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchFarmLandsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchFarmLandsAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const farmLandsReducer = farmLandsSlice.reducer;
export const { setListAction, setDetailsAction, clearDetailsAction, setListCountAction } = farmLandsSlice.actions;
// async actions

export const fetchFarmLandsAction = createAsyncThunk<void, ILoopbackFilter>(
  `${MODULE_NAME}/fetchFarmLands`,
  async (filter, { dispatch }) => {
    const count = await farmLandsService.listCount(filter);
    const dtos = await farmLandsService.list(filter);
    const models = dtos.map((dto) => {
      const model = new FarmLand();
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
    // TODO: depends of optimisation needs, we could change it only if filter changes
    dispatch(setListCountAction(count.count));
  }
);

export const fetchFarmLandByIdAction = createAsyncThunk<void, string>(
  `${MODULE_NAME}/fetchFarmLandById`,
  async (id, { dispatch }) => {
    if (id === null) {
      return;
    }

    const dto = await farmLandsService.get(id);
    const model = new FarmLand();
    model.updateFromDto(dto);
    dispatch(setDetailsAction(model));
  }
);

export const fetchFarmLandIdByArcGisIdAction = createAsyncThunk<string | null, string>(
  `${MODULE_NAME}/fetchFarmLandByArcGisId`,
  async (id) => {
    if (id === null) {
      return null;
    }

    const dto = await farmLandsService.getByArcGisId(id);
    return dto ? dto.id : null;
  }
);

export const fetchFarmLandsByFarmId = createAsyncThunk<
  IFarmLandDto[],
  { farmId: string; excludeGeometry: boolean; filter?: ILoopbackFilter }
>(`${MODULE_NAME}/fetchFarmLandsByFarmId`, async (data) => {
  const dto = await farmLandsService.listByFarmId(data);
  return dto;
});
