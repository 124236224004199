import { Box, Button, CircularProgress, TextField, Typography, styled } from "@material-ui/core";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getLoading } from "../../../../authentication/store/auth.selector";
import { resetPassword } from "../../../../authentication/store/auth.slice";
import { AlertComponent } from "../../../../components/alerts";
import { PATHS } from "../../../../constant";
import { useAppDispatch } from "../../../../store";

const FormContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  "& > *": {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  "& input": {
    padding: theme.spacing(1),
  },
}));

interface IFormData {
  email: string;
}

export const RestorePassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getLoading);
  const navigate = useNavigate();
  const [showError, setShowShowError] = React.useState<boolean>(false);
  const {
    errors,
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<IFormData>({
    mode: "onChange",
    defaultValues: { email: "" },
  });

  const submitHandler = useCallback(
    async (data: IFormData) => {
      const { email } = data;
      try {
        await dispatch(resetPassword(email)).unwrap();
        navigate(PATHS.MAIN_PAGE);
      } catch (_) {
        setShowShowError(true);
      }
    },
    [dispatch, navigate]
  );

  return (
    <FormContainer component={"form"} onSubmit={handleSubmit(submitHandler)}>
      <Typography variant="body2">Введите указанный при регистрации адрес электронной почты</Typography>

      {showError && (
        <AlertComponent
          type="error"
          text="Указанная почта не найдена, проверьте корректность адреса или обратитесь к системному администратору."
        />
      )}

      <Controller
        name={"email"}
        control={control}
        rules={{
          required: "Поле обязательно для заполнения",
        }}
        render={({ ...field }) => (
          <TextField
            {...field}
            required={true}
            fullWidth={true}
            type={"email"}
            error={!!errors.email}
            label={"Адрес электронной почты"}
            helperText={!!errors.email ? errors.email.message : ""}
          />
        )}
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={(isDirty && !isValid) || isLoading}
        fullWidth={true}
      >
        {isLoading ? <CircularProgress size={20} /> : "Отправить запрос на изменение"}
      </Button>
    </FormContainer>
  );
};
