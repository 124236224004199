import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { ITechOperationParamWithArrayParamsDto } from "../shared/dtos/tech-operation-param.dto";
import { ITechOperationDto } from "../shared/dtos/tech-operation.dto";
import { ITechOperationsState } from "../shared/interfaces/tech-operations-state";
import { TechOperation } from "../shared/models/tech-operation";
import { techOperationsService } from "../shared/services/tech-operations.service";

const initialState: ITechOperationsState = {
  isLoading: false,
  list: [],
  listCount: 0,
};

export const MODULE_NAME = "techOperationsPage";
export const techOperationsSlice = createSlice({
  name: MODULE_NAME,
  initialState: initialState,
  reducers: {
    setListAction(state, action: PayloadAction<TechOperation[]>): void {
      state.list = action.payload;
    },
    setListCountAction(state, action: PayloadAction<number>): void {
      state.listCount = action.payload;
    },
    removeTechOperationAction(state, action: PayloadAction<string>): void {
      state.list = state.list.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTechOperationsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchTechOperationsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTechOperationsAction.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const techOperationsReducer = techOperationsSlice.reducer;
export const { setListAction, setListCountAction, removeTechOperationAction } = techOperationsSlice.actions;

// async actions
let abortController: AbortController | null = null;
export const fetchTechOperationsAction = createAsyncThunk<void, ILoopbackFilter>(
  `${MODULE_NAME}/fetchTechOperations`,
  async (filter, { getState, dispatch }) => {
    // prevent multiple calls
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    let count: { count: number };
    let dtos: ITechOperationDto[];
    try {
      count = await techOperationsService.listCount(filter, { signal: abortController.signal });
      dtos = await techOperationsService.list(filter, { signal: abortController.signal });
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "AbortError") {
          return;
        }
      }
      throw e;
    }
    abortController = null;

    const models = dtos.map((dto) => {
      const model = new TechOperation(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
    // TODO: depends of optimisation needs, we could change it only if filter changes
    dispatch(setListCountAction(count.count));
  }
);

export const fetchTechOperationByFarmLandAndTechOperationGroupIdsAndOperationNumberAction = createAsyncThunk<
  ITechOperationDto[],
  {
    farmLandId: string;
    techOperationGroupId: string;
    operationNumber: number;
  }
>(`${MODULE_NAME}/fetchTechOperationsByFarmLandAndTechOperationGroupIdsAndOperationNumber`, async (data) => {
  const dto = await techOperationsService.getOperationsByFarmLandAndTechOperationGroupIdsAndOperationNumber(data);
  return dto;
});

export const fetchTechOperationSubGroupIdsByCropTypeId = createAsyncThunk<string[], string>(
  `${MODULE_NAME}/fetchTechOperationSubGroupIdsByCropTypeId`,
  async (data) => {
    const dto = await techOperationsService.getTechOperationSubGroupIdsByCropTypeId(data);
    return dto.map((item) => item.techOperationSubGroupId);
  }
);

export const fetchTechOperationSubGroupParamsBySubGroupId = createAsyncThunk<
  ITechOperationParamWithArrayParamsDto[],
  string
>(`${MODULE_NAME}/fetchTechOperationParams`, async (data) => {
  const dto = await techOperationsService.getTechOperationSubGroupParamsWithArrayParamsBySubGroupId(data);
  return dto.map((item) => item.TechOperationParam);
});

export const getMaxOperationNumbersByFarmLandAndTechOperationGroupIdsAction = createAsyncThunk<
  number,
  {
    farmLandId: string;
    techOperationGroupId: string;
  }
>(`${MODULE_NAME}/getMaxOperationNumbersByFarmLandAndTechOperationGroupIds`, async (data) => {
  const res = await techOperationsService.getMaxOperationNumbersByFarmLandAndTechOperationGroupIds(data);
  return res;
});

export const deleteTechOperationAction = createAsyncThunk<Promise<unknown>, string>(
  `${MODULE_NAME}/deleteTechOperation`,
  async (techOperationId, { dispatch }) => {
    return techOperationsService
      .delete(techOperationId)
      .then(() => dispatch(removeTechOperationAction(techOperationId)));
  }
);

// eslint-disable-next-line
export const getWeeklyFileAction = createAsyncThunk<any, Record<string, string | string[]>>(
  `${MODULE_NAME}/getWeeklyFile`,
  async (data) => {
    return techOperationsService.getWeeklyFile(data);
  }
);
