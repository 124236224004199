import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  polygon: {
    stroke: "#333",
    strokeWidth: 1,
    "&:hover": {
      stroke: "#26f4f4",
      strokeWidth: 3,
      transition: "0.2s",
    },
  },
  circularProgressComponent: {
    position: "absolute",
    left: "-56px",
    width: "104%",
    height: "100%",
  },
}));
