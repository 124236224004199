import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout";
import { CATALOG_SECTIONS_ENUM } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout-constants";
import { useAppDispatch } from "../../../../store";
import {
  deleteAppUsersAction,
  fetchAppUsersAction,
  getSelectedIds,
  setSearchText,
  setSelectedIdsAction,
} from "../../store";

const AppUsersTable = lazy(() => import("../app-users-table/app-users-table"));

const AppUsersTab = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const selectedIds = useSelector(getSelectedIds);
  const onDelete = useCallback(
    () => dispatch(deleteAppUsersAction(selectedIds)).then(() => dispatch(fetchAppUsersAction())),
    [selectedIds, dispatch]
  );
  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback((value: string) => dispatch(setSearchText(value)), [dispatch]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.APPUSERS}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <AppUsersTable />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default AppUsersTab;
