import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { getLoading } from "../../../../authentication/store/auth.selector";
import { login } from "../../../../authentication/store/auth.slice";
import { LoginFormDataTypes } from "../../../../common-types";
import { AlertComponent } from "../../../../components/alerts";
import { PATHS } from "../../../../constant";
import { useAppDispatch } from "../../../../store";
import AvatarImg from "../../img/img_crop.jpg";

const FormContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  "& > *": {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  "& input": {
    padding: theme.spacing(1),
  },
}));

const RestorePasswordLink = styled(NavLink)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export const LoginForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getLoading);
  const navigate = useNavigate();
  const [showError, setShowShowError] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const {
    handleSubmit,
    errors,
    control,
    formState: { isValid, isDirty },
  } = useForm<LoginFormDataTypes>({
    defaultValues: { email: "", password: "" },
    mode: "onChange",
  });

  const submitHandler = useCallback(
    async (data: LoginFormDataTypes) => {
      const { email, password } = data;
      try {
        await dispatch(login({ email, password })).unwrap();
        navigate(PATHS.MAIN_PAGE);
      } catch (_) {
        setShowShowError(true);
      }
    },
    [dispatch, navigate]
  );

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), [setShowPassword]);
  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault(),
    []
  );

  return (
    <FormContainer component={"form"} onSubmit={handleSubmit(submitHandler)}>
      <Avatar src={AvatarImg} style={{ height: "105px", width: "105px" }} />

      <Typography variant="h5">EkoCrop</Typography>

      <Typography variant="body2">Введите логин и пароль выданные Вам администратором</Typography>

      {showError && <AlertComponent type="error" text="Неверный логин или пароль. Повторите попытку ещё раз." />}

      <Controller
        name={"email"}
        control={control}
        rules={{
          required: "Поле обязательно для заполнения",
        }}
        render={({ ...field }) => (
          <TextField
            {...field}
            required={true}
            fullWidth={true}
            type={"email"}
            error={!!errors.email}
            label={"Логин (e-mail)"}
            helperText={!!errors.email ? errors.email.message : ""}
          />
        )}
      />

      <Controller
        name={"password"}
        control={control}
        rules={{
          required: "Поле обязательно для заполнения",
        }}
        render={({ ...field }) => (
          <FormControl fullWidth={true} error={!!errors.password}>
            <InputLabel required htmlFor="password">
              Пароль
            </InputLabel>
            <Input
              required={true}
              type={showPassword ? "text" : "password"}
              {...field}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Показать пароль"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility color="primary" /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{!!errors.password ? errors.password.message : ""}</FormHelperText>
          </FormControl>
        )}
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={(isDirty && !isValid) || isLoading}
        fullWidth={true}
      >
        {isLoading ? <CircularProgress size={20} /> : "Войти"}
      </Button>

      <RestorePasswordLink to={"restore-password"}>Забыли пароль?</RestorePasswordLink>
    </FormContainer>
  );
};
