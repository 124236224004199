import { IFilter } from "../../../../shared/interface";
import { BaseService } from "../../../../shared/services";
import { normalizeFilter } from "../../../../shared/utils/normalize-filter";
import { IWeedDto } from "../dtos/weeds";

class WeedsService extends BaseService<IWeedDto, Partial<IFilter>> {
  private path = "Weeds";

  async get(id: string): Promise<IWeedDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  // eslint-disable-next-line
  async add(data: IWeedDto): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async update(data: IWeedDto): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  async list(filter: Partial<IFilter>): Promise<IWeedDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    });
  }

  async listCount(filter: Partial<IFilter>): Promise<{ count: number }> {
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    });
  }
}

export const weedsService = new WeedsService();
