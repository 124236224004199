import { IAppUsersDto } from "../dtos/app-users";

export class AppUser {
  readonly id: string;
  lastName: string;
  firstName: string;
  middleName: string;
  fullName: string;
  positionName: string;
  defaultFarmId: string;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IAppUsersDto {
    return {
      id: this.id,
      lastName: this.lastName,
      firstName: this.firstName,
      middleName: this.middleName,
      fullName: this.fullName,
      positionName: this.positionName,
      defaultFarmId: this.defaultFarmId,
    };
  }

  updateFromDto(dto: IAppUsersDto): void {
    this.lastName = dto.lastName;
    this.firstName = dto.firstName;
    this.middleName = dto.middleName;
    this.fullName = dto.fullName;
    this.positionName = dto.positionName;
    this.defaultFarmId = dto.defaultFarmId;
  }
}
