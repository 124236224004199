import { ILoopbackFilter } from "../../../../modules/filter/shared/interfaces/loopback";
import { BaseService } from "../../../../shared/services";
import { ITechOperationSubGroupParamWithArrayParamsDto } from "../dtos/tech-operation-param.dto";
import { ITechOperationDto } from "../dtos/tech-operation.dto";

class TechOperationsService extends BaseService<ITechOperationDto, ILoopbackFilter> {
  private path = "TechOperationAgregatedsV2";

  async get(id: string): Promise<ITechOperationDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  async list(filter: ILoopbackFilter, options: { signal?: AbortSignal } = {}): Promise<ITechOperationDto[]> {
    const { signal = null } = options;
    return this.fetch(this.path, {
      method: "GET",
      searchParams: { filter: this.normalizeFilter(filter) },
      signal,
    });
  }

  async listCount(filter: ILoopbackFilter, options: { signal?: AbortSignal } = {}): Promise<{ count: number }> {
    const { signal = null } = options;
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: filter as Record<string, unknown>,
      signal,
    });
  }

  async getMaxOperationNumbersByFarmLandAndTechOperationGroupIds({
    farmLandId,
    techOperationGroupId,
  }: {
    farmLandId: string;
    techOperationGroupId: string;
  }): Promise<number> {
    const maxOperationNumber = await this.fetch(this.path, {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            farmLandId,
            techOperationGroupId,
          },
          order: "operationNumber DESC",
          limit: 1,
        },
      },
    });

    return maxOperationNumber[0]?.operationNumber || 0;
  }

  async getOperationsByFarmLandAndTechOperationGroupIdsAndOperationNumber({
    farmLandId,
    techOperationGroupId,
    operationNumber,
  }: {
    farmLandId: string;
    techOperationGroupId: string;
    operationNumber: number;
  }): Promise<ITechOperationDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            farmLandId,
            techOperationGroupId,
            operationNumber,
          },
        },
      },
    });
  }

  async getTechOperationSubGroupParamsWithArrayParamsBySubGroupId(
    subGroupId: string
  ): Promise<ITechOperationSubGroupParamWithArrayParamsDto[]> {
    return this.fetch("TechOperationSubGroupParams", {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            subGroupId: subGroupId,
          },
          include: {
            relation: "TechOperationParam",
            scope: {
              include: {
                relation: "techOperationArrayParams",
                scope: {
                  include: "techOperationArrayParamsDetails",
                },
              },
            },
          },
        },
      },
    });
  }

  async getTechOperationSubGroupIdsByCropTypeId(
    cropTypeId: string
  ): Promise<Record<"techOperationSubGroupId", string>[]> {
    return this.fetch("TechOperationSubGroupCropTypeRelations", {
      method: "GET",
      searchParams: {
        filter: {
          where: {
            cropTypeId,
          },
          fields: ["techOperationSubGroupId"],
        },
      },
    });
  }

  // eslint-disable-next-line
  async getWeeklyFile(data): Promise<void> {
    try {
      const link = document.createElement("a");
      link.href = this.getUrlWithSearch([this.ROOT_URL, "TechOpReports/getWeeklyFile"].join("/"), data);
      link.target = "_blank";
      link.setAttribute("download", ""); // This attribute prompts the browser to download the file
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  //eslint-disable-next-line
  async add(data: ITechOperationDto): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  //eslint-disable-next-line
  async update(data: ITechOperationDto): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch(this.path, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  //eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  normalizeFilter(filter: ILoopbackFilter): ILoopbackFilter {
    return {
      include: [
        { relation: "appUser", scope: { fields: ["fullName"] } },
        { relation: "crop", scope: { fields: ["name"] } },
        { relation: "farm", scope: { fields: ["name"] } },
        { relation: "farmLand", scope: { fields: ["name"] } },
        { relation: "techOperationGroup", scope: { fields: ["name"] } },
      ],
      ...filter,
    };
  }
}

export const techOperationsService = new TechOperationsService();
