import { ICropAggregationDto } from "../../../../pages/map-page/shared/dtos/crop-aggregation.dto";
import { IFilter } from "../../../../shared/interface/filter";
import { BaseService } from "../../../../shared/services/base.service";
import { ILoopbackFilter } from "../../../filter/shared/interfaces/loopback";
import { IFarmsDto } from "../dtos/farms";

class FarmsService extends BaseService<IFarmsDto, Partial<IFilter>> {
  private path = "Farms";

  async get(id: string): Promise<IFarmsDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  async list(): Promise<IFarmsDto[]> {
    return this.fetch(this.path, {
      method: "GET",
    });
  }

  async getCropAggregation(id: string, seasonName: string, filter: ILoopbackFilter): Promise<ICropAggregationDto[]> {
    return this.fetch([this.path, id, "cropAggregation"].join("/"), {
      method: "GET",
      searchParams: { seasonName, filter },
    });
  }
}

export const farmsService = new FarmsService();
