import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeoJSON, LatLngBounds, LatLngExpression } from "leaflet";

import { TArcgisMapCropPropsDto } from "../shared/dtos/arcgis-map-crop-props.dto";
import { IFarmDto } from "../shared/dtos/farm.dto";
import { IFarmNameTuple } from "../shared/interfaces/farm-name-tuple";
import { IMapPageState } from "../shared/interfaces/map-page-state";
import { FarmNameTuple } from "../shared/models/farm-name-tuple";
import { appUsersService } from "../shared/services/app-user.service";
import { TBriefCropGeoJson, arcgisCropsService } from "../shared/services/arcgis-crops.service";
import { cropTypesService } from "../shared/services/crop-types.service";
import { farmLandCropsGeoService } from "../shared/services/farm-land-crops.geo-service";
import { getMapPageState } from "./map-page.selector";

export const MODULE_NAME = "mapPage";
const initialMapPageState: IMapPageState = {
  geoJson: new GeoJSON<TArcgisMapCropPropsDto>(),
  farmLandsData: [],
  isLoading: false,
  center: [55, 46],
  zoom: 6,
  bounds: new LatLngBounds([
    [54, 84],
    [53, 83],
  ]),
  selectedCropNames: [],
  highlightedFarmLandId: null,
  isDrawerOpen: true,

  // TODO: remove unused
  farmLandCropsGeoFeatures: [],
  arcgisToken: "",
  farmNameTuples: [],
  selectedFarmName: "",
  defaultFarmName: "",
  cropTypes: [],
};

export const mapPageSlice = createSlice({
  name: MODULE_NAME,
  initialState: initialMapPageState,
  reducers: {
    setHighlightedFarmLandId(state, action: PayloadAction<string | null>): void {
      state.highlightedFarmLandId = action.payload;
    },
    setCenterAction(state, action: PayloadAction<LatLngExpression>): void {
      state.center = action.payload;
    },
    setZoomAction(state, action: PayloadAction<number>): void {
      state.zoom = action.payload;
    },
    setBoundsAction(state, action: PayloadAction<LatLngBounds>): void {
      const bounds = action.payload;
      if (bounds.isValid()) {
        state.bounds = bounds;
      }
    },
    setGeoJsonAction(state, action: PayloadAction<GeoJSON<TArcgisMapCropPropsDto>>): void {
      state.geoJson = action.payload;
    },
    setFarmLandCropsGeoFeaturesAction(state, action): void {
      state.farmLandCropsGeoFeatures = action.payload;
    },
    setArcGisTokenAction(state, action): void {
      state.arcgisToken = action.payload;
    },
    setSelectedCropNamesAction(state, action): void {
      state.selectedCropNames = action.payload;
    },
    setSelectedFarmNameAction(state, action): void {
      state.selectedFarmName = action.payload;
    },
    setFarmNameTuplesAction(state, action): void {
      state.farmNameTuples = action.payload;
    },
    setDefaultFarmNameAction(state, action): void {
      state.defaultFarmName = action.payload;
    },
    setCropTypesAction(state, action): void {
      state.cropTypes = action.payload;
    },
    setFarmLandsDataAction(state, action: PayloadAction<TArcgisMapCropPropsDto[]>): void {
      state.farmLandsData = action.payload;
    },
    toggleDrawerOpenAction(state, acion: PayloadAction): void {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGeoJsonAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchGeoJsonAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGeoJsonAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const mapReducer = mapPageSlice.reducer;
export const {
  setHighlightedFarmLandId,
  setFarmLandCropsGeoFeaturesAction,
  setArcGisTokenAction,
  setSelectedCropNamesAction,
  setSelectedFarmNameAction,
  setFarmNameTuplesAction,
  setDefaultFarmNameAction,
  setCropTypesAction,
  setGeoJsonAction,
  setZoomAction,
  setCenterAction,
  setBoundsAction,
  setFarmLandsDataAction,
  toggleDrawerOpenAction,
} = mapPageSlice.actions;

export const fetchGeoJsonAction = createAsyncThunk<void, { farm: string; season: number }>(
  `${MODULE_NAME}/fetchGeoJsonAction`,
  async ({ farm, season }, { dispatch, getState }) => {
    const featureCollection = await arcgisCropsService.getMapData({
      farm,
      season,
    });
    const data = featureCollection.features.map((item) => item.properties);
    const highlightedId = getMapPageState(getState()).highlightedFarmLandId;
    // eslint-disable-next-line
    const highlightedFeature = featureCollection.features.find(
      (feature) => feature.properties.GDB_ARCHIVE_OID.toString() === highlightedId
    );

    const geoJson = new GeoJSON(featureCollection);

    dispatch(setGeoJsonAction(geoJson));
    dispatch(setBoundsAction(geoJson.getBounds()));
    dispatch(setCenterAction(geoJson.getBounds().getCenter()));
    dispatch(setFarmLandsDataAction(data));
  }
);

export const fetchArcGisTokenAction = createAsyncThunk(
  `${MODULE_NAME}/fetchArcGisTokenAction`,
  async (_, { dispatch }) => {
    const arcGisToken = await appUsersService.getArcgisToken();
    farmLandCropsGeoService.setToken(arcGisToken);
    dispatch(setArcGisTokenAction(arcGisToken));
  }
);

export const fetchAvailableFarmsAction = createAsyncThunk(
  `${MODULE_NAME}/fetchAvailableFarmsAction`,
  async (_, { dispatch }) => {
    const farmDtos = await appUsersService.availableFarmsList();
    const farmNameTuples = farmDtos
      .map(
        (farmDto: IFarmDto) =>
          new FarmNameTuple({
            id: farmDto.id,
            nameArcgis: farmDto.farmMap.nameArcgis,
            nameEkocrop: farmDto.farmMap.nameEkocrop,
          })
      )
      .sort((a: IFarmNameTuple, b: IFarmNameTuple) =>
        a.nameEkocrop > b.nameEkocrop ? 1 : a.nameEkocrop < b.nameEkocrop ? -1 : 0
      );

    dispatch(setFarmNameTuplesAction(farmNameTuples));
  }
);

export const fetchDefaultFarmNameAction = createAsyncThunk(
  `${MODULE_NAME}/fetchDefaultFarmNameAction`,
  async (_, { dispatch }) => {
    const defaultFarmName = await appUsersService.getDefaultFarmName();
    dispatch(setDefaultFarmNameAction(defaultFarmName));
  }
);

export const fetchCropTypesAction = createAsyncThunk(`${MODULE_NAME}/fetchCropTypesAction`, async (_, { dispatch }) => {
  const cropTypes = await cropTypesService.cropTypeColorsList();
  dispatch(setCropTypesAction(cropTypes));
});

export const getBriefCropAction = createAsyncThunk<TBriefCropGeoJson | null, number>(
  `${MODULE_NAME}/getBriefCropAction`,
  async (id, { dispatch }) => {
    dispatch(fetchArcGisTokenAction());
    return await arcgisCropsService.getBriefData(id);
  }
);
