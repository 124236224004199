import { LatLngLiteral } from "leaflet";

import { IFarmLandDto } from "../dtos/farm-land.dto";

export class FarmLand {
  id: string;
  name: string;
  area: number;
  arcgisObjectId: number;

  shape: LatLngLiteral[] = [];
  shapeCenter: LatLngLiteral = { lat: 0, lng: 0 };

  // FieldClimate.com station id (TODO: later need build our weather integrator and get data from it use our station id)
  importMeteoStationId: string | null = null;

  // TODO: interchange fieldId: string to field: ILabel
  farmId: string;
  departmentId: string;
  cropTypeId: string;

  get hasShape(): boolean {
    return this.shape.length > 0;
  }

  get hasMeteoData(): boolean {
    return this.importMeteoStationId !== null;
  }

  get asDto(): IFarmLandDto {
    return {
      id: this.id,
      arcgisGuid: this.id,
      name: this.name,
      area: this.area,
      farmId: this.farmId,
      cropTypeId: this.cropTypeId,
      departmentId: this.departmentId,
      importMeteoStationId: this.importMeteoStationId,
      arcgisObjectId: this.arcgisObjectId,
    };
  }

  updateFromDto(dto: IFarmLandDto): void {
    this.id = dto.id;
    this.name = dto.name;
    this.farmId = dto.farmId;
    this.area = dto.area || 0;
    this.departmentId = dto.departmentId || "не известно";
    this.importMeteoStationId = dto.importMeteoStationId || null;
    this.arcgisObjectId = dto.arcgisObjectId;
    this.cropTypeId = dto.cropTypeId;

    this.shapeCenter = {
      lat: dto.lat || 0,
      lng: dto.lng || 0,
    };

    if (dto.shape) {
      this.shape = dto.shape;
    }
  }
}
