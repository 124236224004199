export enum ArcGisGeometryType {
  Envelope = "esriGeometryEnvelope",
  Point = "esriGeometryPoint",
  Polyline = "esriGeometryPolyline",
  Polygon = "esriGeometryPolygon",
  Multipoint = "esriGeometryMultipoint",
}

export enum ArcGisSpatialRel {
  Intersects = "esriSpatialRelIntersects",
  Contains = "esriSpatialRelContains",
  Within = "esriSpatialRelWithin",
  Crosses = "esriSpatialRelCrosses",
  EnvelopeIntersects = "esriSpatialRelEnvelopeIntersects",
  IndexIntersects = "esriSpatialRelIndexIntersects",
  Overlaps = "esriSpatialRelOverlaps",
  Touches = "esriSpatialRelTouches",
  Relation = "esriSpatialRelRelation",
}

export enum ArcGisFormat {
  Json = "pjson", // TODO: remove in prod (use json value)
  Geojson = "geojson",
  HTML = "html",
}

export interface IArcGisFilter {
  where: string; // SQL where
  objectIds?: number[];
  geometry?: Record<string, unknown>;
  geometryType?: ArcGisGeometryType;
  spatialRel?: ArcGisSpatialRel;
  outFields?: string;
  returnGeometry?: boolean;
  returnCentroid?: boolean;
  returnIdsOnly?: boolean;
  returnCountOnly?: boolean;
  orderByFields?: string[];
  resultRecordCount?: number;
  returnDistinctValues?: boolean;
  f?: ArcGisFormat;
}
