import React, { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IProps<T> {
  name: string;
  defaultValue?: T;
}

export const RHFInputHidden = <T extends unknown>(props: IProps<T>): JSX.Element => {
  const rhfMethods = useFormContext();

  const renderInput = useCallback(() => <input type="hidden" />, []);

  return (
    <Controller name={props.name} defaultValue={props.defaultValue} control={rhfMethods.control} render={renderInput} />
  );
};
