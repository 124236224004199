import { ruRU } from "@material-ui/core/locale";
import { createTheme } from "@material-ui/core/styles";

import { overrides } from "./overrides";
import { palette } from "./palette";
import { typography } from "./typography";

export const MuiTheme = createTheme(
  {
    palette: palette,
    typography: typography,
    overrides: overrides,
  },
  ruRU
);
