import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { ICropsState } from "../shared";
import { MODULE_NAME } from "./crops.slice";

// eslint-disable-next-line
export const getCropsState = (rootState: any): ICropsState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getCropsState, (state: ICropsState) => state.isLoading);
export const getList = createDraftSafeSelector(getCropsState, (state: ICropsState) => state.list);
export const getSelectedIds = createDraftSafeSelector(getCropsState, (state: ICropsState) => state.selectedIds);
export const getSearchText = createDraftSafeSelector(getCropsState, (state: ICropsState) => state.searchText);
