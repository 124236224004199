import { ISeedsDto } from "../dtos/seeds";
import { Units } from "../enums/units";

export class Seed {
  readonly id: string;
  name: string;
  cropName: string;
  sortName: string;
  varietyName: string;
  unit?: typeof Units[keyof typeof Units];

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): ISeedsDto {
    return {
      id: this.id,
      name: this.name,
      cropName: this.cropName,
      sortName: this.sortName,
      varietyName: this.varietyName,
      unit: this.unit,
    };
  }

  updateFromDto(dto: ISeedsDto): void {
    this.name = dto.name;
    this.cropName = dto.cropName;
    this.sortName = dto.sortName;
    this.varietyName = dto.varietyName;
    this.unit = dto.unit;
  }
}
