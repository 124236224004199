import { v4 as uuidv4 } from "uuid";

import { ITechOperationAssetDto } from "../dtos/tech-operation-asset.dto";
import { TechOperationAssetEntityID } from "../enums/tech-operation-asset-entity";
import { ITechOperationAssetFormData } from "../interfaces/tech-operation-asset-form-data";

export class TechOperationAsset {
  readonly id: string;
  farmId: string;
  techOperationId: string;
  name: string;
  assetTypeId: string | null;
  entityType: string;
  entityId: TechOperationAssetEntityID;
  quantity: string | number;
  unit: string;
  seedUnitWeight: number | null;
  departmentId?: string;
  deletedAt?: number; // timestamp
  isDeleted?: boolean;
  createdAt?: number; // timestamp
  updatedAt?: number; // timestamp
  createdByUserId?: string;
  updatedByUserId?: string;
  appUserId?: string;

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  get asDto(): ITechOperationAssetDto {
    return {
      id: this.id,
      farmId: this.farmId,
      techOperationId: this.techOperationId,
      entityType: this.entityType,
      entityId: this.entityId,
      quantity: Number(this.quantity),
      unit: this.unit,
      seedUnitWeight: this.seedUnitWeight,
      assetTypeId: this.assetTypeId,
      name: this.name,
    };
  }

  updateFromDto(dto: ITechOperationAssetDto): void {
    this.farmId = dto.farmId;
    this.techOperationId = dto.techOperationId;
    this.entityType = dto.entityType;
    this.entityId = dto.entityId;
    this.quantity = dto.quantity;
    this.unit = dto.unit;
    this.seedUnitWeight = dto.seedUnitWeight;
    this.assetTypeId = dto.assetTypeId;
    this.name = dto.name;
    this.createdAt = dto.createdAt;
  }

  get asFormData(): ITechOperationAssetFormData {
    return {
      _id: this.id,
      entityType: this.entityType || "",
      entityId: this.entityId || "",
      quantity: this.quantity || "",
      unit: this.unit || "",
      seedUnitWeight: this.seedUnitWeight || null,
      createdAt: this.createdAt,
      assetTypeId: this.assetTypeId || null,
      name: this.name || "",
    };
  }

  updateFromFormData(data: ITechOperationAssetFormData): TechOperationAsset {
    this.entityId = data.entityId;
    this.entityType = data.entityType;
    this.quantity = data.quantity;
    this.unit = data.unit;
    this.seedUnitWeight = data.seedUnitWeight;
    this.name = data.name;
    this.assetTypeId = data.assetTypeId;
    return this;
  }

  setAppUserId(appUserId: string): void {
    this.appUserId = appUserId;
  }

  setEntityId(entityId: TechOperationAssetEntityID): TechOperationAsset {
    this.entityId = entityId;
    return this;
  }

  setFarmId(farmId: string): TechOperationAsset {
    this.farmId = farmId;
    return this;
  }

  setTechOperationId(techOperationId: string): TechOperationAsset {
    this.techOperationId = techOperationId;
    return this;
  }

  setAssetTypeId(assetTypeId: string | null): TechOperationAsset {
    this.assetTypeId = assetTypeId;
    return this;
  }
}
