import { Dispatch } from "redux";

import { Util } from "../../utils/utils";

const INFO_NAMESPACE = "@infoData";

//TODO any
export const SET_INFO_DATA = `${INFO_NAMESPACE}/SET_INFO_DATA`;
// eslint-disable-next-line
export const setInfoData = (info_data: any) => ({
  type: SET_INFO_DATA,
  payload: info_data,
});

export const fetchCatalogInfoData =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    await Promise.all([
      Util.getFeaturesAPI("CropTypes").then((cropTypes) =>
        dispatch(
          setInfoData({
            cropTypes: cropTypes,
          })
        )
      ),
      Util.getFeaturesAPI("Departments").then((departments) =>
        dispatch(
          setInfoData({
            departments: departments,
          })
        )
      ),
      Util.getFeaturesAPI("Farms").then((farms) =>
        dispatch(
          setInfoData({
            farms: farms,
          })
        )
      ),
      Util.getFeaturesAPI("Positions").then((positions) =>
        dispatch(
          setInfoData({
            positions: positions,
          })
        )
      ),
      Util.getFeaturesAPI("Seasons").then((seasons) =>
        dispatch(
          setInfoData({
            seasons: seasons,
          })
        )
      ),
      Util.getFeaturesAPI("Seeds").then((seeds) =>
        dispatch(
          setInfoData({
            seeds: seeds,
          })
        )
      ),
      Util.getFeaturesAPI("TechTypes").then((techTypes) =>
        dispatch(
          setInfoData({
            techTypes: techTypes,
          })
        )
      ),
      Util.getFeaturesAPI("TechOperationGroups").then((techOperationGroups) =>
        dispatch(
          setInfoData({
            techOperationGroup: techOperationGroups,
          })
        )
      ),
      Util.getFeaturesAPI("TechOperationSubGroups").then((techOperationSubGroups) =>
        dispatch(
          setInfoData({
            techOperationSubGroup: techOperationSubGroups,
          })
        )
      ),
      Util.getFeaturesAPI("WorkPlaces").then((workPlaces) =>
        dispatch(
          setInfoData({
            workPlace: workPlaces,
          })
        )
      ),
      Util.getFeaturesAPI("Roles").then((roles) =>
        dispatch(
          setInfoData({
            roles: roles,
          })
        )
      ),
      Util.getFeaturesAPI("PhenoPhases").then((phenoPhases) =>
        dispatch(
          setInfoData({
            phenoPhases: phenoPhases,
          })
        )
      ),
      Util.getFeaturesAPI("TechOperationScalars").then((techOperationsScalars) =>
        dispatch(
          setInfoData({
            techOperationsScalars: techOperationsScalars,
          })
        )
      ),
    ]);

    dispatch(
      setInfoData({
        techAssetCategory: ["Самоходная", "Прицепная"],
      })
    );
  };
