import { useEffect } from "react";

import { useAppDispatch } from "../../../../../store";
import { setFilterDisablingAction } from "../../../store/filters.slice";
import { FilterName } from "../../enums/filter-name";

export const useDisableGlobalFilters = (): void => {
  const dispatch = useAppDispatch();

  // disable global farm name filter on these pages
  useEffect(() => {
    dispatch(
      setFilterDisablingAction({
        filter: FilterName.ArcgisFarmName,
        value: true,
      })
    );
    dispatch(setFilterDisablingAction({ filter: FilterName.Season, value: true }));
    return () => {
      dispatch(
        setFilterDisablingAction({
          filter: FilterName.ArcgisFarmName,
          value: false,
        })
      );
      dispatch(setFilterDisablingAction({ filter: FilterName.Season, value: false }));
    };
    // eslint-disable-next-line
  }, [dispatch, setFilterDisablingAction]);
};
