import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../../store";
import { getInfoSeasons } from "../../../../info-data";
import { ISeason } from "../../../../info-data/shared/interfaces/season";
import { getFilter, getFilterComposition, getFilterDisabling, getInit } from "../../../store/filters.selector";
import { setFilterAction } from "../../../store/filters.slice";
import { FilterName } from "../../enums/filter-name";

const useStyles = makeStyles(() => ({
  label: {
    position: "relative",
    paddingRight: 25,
    "& + div": {
      marginTop: 0,
    },
  },
}));

export const useSeasonEffect = (): void => {
  const dispatch = useAppDispatch();
  const isFilterInit = useSelector(getInit);
  const seasons = useSelector(getInfoSeasons);
  const { SeasonId } = useSelector((state) => getFilterComposition(state, [FilterName.SeasonId]));
  const currentSeasonId = useMemo(() => {
    const currentSeason = seasons.find((item) => item.isCurrent);
    return currentSeason ? currentSeason.id : seasons.length ? seasons[0].id : null;
  }, [seasons]);

  useEffect(() => {
    if (!seasons.length || !isFilterInit) {
      return;
    }

    if (SeasonId === null) {
      dispatch(setFilterAction({ filter: FilterName.SeasonId, value: currentSeasonId }));
    } else {
      // update season name if seasonId has been changed
      const season = (seasons.find((item) => item.id === SeasonId) as ISeason).name;
      dispatch(setFilterAction({ filter: FilterName.Season, value: season }));
    }
    // eslint-disable-next-line
  }, [seasons, SeasonId, currentSeasonId, isFilterInit]);
};

export const FilterFieldSeasonId = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const value = useSelector((state) => getFilter(state, FilterName.SeasonId));
  const seasons = useSelector(getInfoSeasons);
  const seasonLabels = useMemo(() => {
    return seasons.sort((a, b) => (a.name < b.name ? 1 : -1)).map((item) => ({ id: item.id, title: item.name }));
  }, [seasons]);
  const defaultValue = useMemo(() => {
    const currentSeason = seasons.find((item) => item.isCurrent);
    return currentSeason ? currentSeason.id : seasons[0]?.id;
  }, [seasons]);
  const isSeasonDisabled = useSelector((state) => getFilterDisabling(state, FilterName.Season));
  const classes = useStyles();

  const onChangeHandler = useCallback(
    (event) => {
      const value = event.target.value;
      dispatch(setFilterAction({ filter: FilterName.SeasonId, value }));
    },
    // eslint-disable-next-line
    [dispatch, setFilterAction]
  );

  useSeasonEffect();

  if (!seasonLabels.length) {
    return <Skeleton width={80} height={60} />;
  }

  return (
    <FormControl margin="normal">
      <InputLabel id="season-label" className={classes.label}>
        Сезон
      </InputLabel>
      <Select
        labelId="season-label"
        id="season-select"
        defaultValue={defaultValue}
        value={value || ""}
        onChange={onChangeHandler}
        disabled={isSeasonDisabled}
      >
        {seasonLabels.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
