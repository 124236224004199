import { Box, Button, Typography } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { ArrayField } from "react-hook-form";

import { TechOperationAssetEntityID } from "../../shared/enums/tech-operation-asset-entity";
import { ITechOperationAssetFormData } from "../../shared/interfaces/tech-operation-asset-form-data";
import { TechOperationEditingAsset } from "../tech-operation-editing-asset/tech-operation-editing-asset";

interface IProps {
  title: string;
  optionsLabel: string;
  noOptionsText: string;
  addButtonText: string;
  entityType: TechOperationAssetEntityID;
  assetsList: Partial<ArrayField<ITechOperationAssetFormData, "id">>[];
  onAssetAppend: (entityType: TechOperationAssetEntityID) => void;
  onAssetRemove: (idx: number, assetId: string) => void;
  sortOptions?: boolean;
  sortUnits?: boolean;
  displayRedAsterisk?: boolean;
}

export const TechOperationAssetsGroup = (props: IProps): JSX.Element => {
  const firstIdxByEntityType: number = useMemo(() => {
    let res = 0;
    props.assetsList.some((item, idx) => {
      if (item?.entityId === props.entityType) {
        res = idx;
        return true;
      }
      return false;
    });
    return res;
  }, [props.assetsList, props.entityType]);

  const assetAppendClicked = useCallback(() => {
    props.onAssetAppend(props.entityType);
  }, [props]);

  return (
    <>
      <Box px={2} pt={4}>
        <Typography variant={"h6"}>
          {props.title}
          {props.displayRedAsterisk && <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>}
        </Typography>
      </Box>

      {props.assetsList.map((item, idx) => {
        return (
          item?.entityId === props.entityType && (
            <TechOperationEditingAsset
              key={item.id}
              idx={idx}
              asset={item}
              isFirst={idx === firstIdxByEntityType}
              optionsLabel={props.optionsLabel}
              noOptionsText={props.noOptionsText}
              onRemove={props.onAssetRemove}
              sortOptions={props.sortOptions}
              sortUnits={props.sortUnits}
            />
          )
        );
      })}

      <Box p={2}>
        <Button onClick={assetAppendClicked} color="primary" variant="contained">
          {props.addButtonText}
        </Button>
      </Box>
    </>
  );
};
