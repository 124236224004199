import { Tab, Tabs } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const SettingsSubmenu = (): JSX.Element => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(
    location.pathname
      .split("/")
      .filter((part, index) => index > 1)
      .join("/") || "appusers/info"
  );
  const handleChange = useCallback((event, newValue) => setTabValue(newValue), [setTabValue]);

  return (
    <Tabs orientation={"vertical"} value={tabValue} onChange={handleChange} aria-label="catalogs submenu">
      <Tab component={Link} to={"appusers/info"} value="appusers/info" label="Пользователь" />
      <Tab component={Link} to={"appusers/password"} value="appusers/password" label="Пароль" />
      {/*<Tab component={Link} to={"interface"} value="interface" label="Интерфейс"/>*/}
      {/*<Tab component={Link} to={"notifications"} value="notifications" label="Уведомления"/>*/}
      {/*<Tab component={Link} to={"support"} value="support" label="Поддержка"/>*/}
    </Tabs>
  );
};
