import { IUserDto } from "../dtos/user.dto";
import { ILoginCredentials } from "../interfaces/login-credentials";
import { ITokenResponse } from "../interfaces/token-response";
import { IValidateTokenResponse } from "../interfaces/validate-token-response";

interface IAuthProps {
  token: string;
  userId: string;
}

class AuthService {
  private readonly baseUrl = process.env.REACT_APP_API_ENDPOINT;

  private async request<T>(url: string, init?: RequestInit): Promise<T> {
    const response = await fetch(url, init);
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error);
    }
    return (await response.json()) as T;
  }

  async getToken(credentials: ILoginCredentials): Promise<ITokenResponse> {
    return this.request<ITokenResponse>(`${this.baseUrl}/AppUsers/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    });
  }

  async getUser(request: IAuthProps): Promise<IUserDto> {
    const { userId, token } = request;
    const url = new URL(`${this.baseUrl}/AppUsers/${userId}`);
    url.searchParams.append("access_token", token);
    return this.request<IUserDto>(url.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async refreshToken(props: IAuthProps): Promise<boolean> {
    const { token, userId } = props;
    const url = new URL(
      `${this.baseUrl}/AppUsers/:id/accessTokens/:token`.replace(
        /:\w+/g,
        (match) => ({ ":id": userId, ":token": token }[match] || match)
      )
    );
    url.searchParams.append("access_token", token);
    try {
      await this.request<IValidateTokenResponse>(url.toString(), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return true;
    } catch {
      return false;
    }
  }

  async logout(props: IAuthProps): Promise<boolean> {
    const { token } = props;
    const url = new URL(`${this.baseUrl}/AppUsers/logout`);
    url.searchParams.append("access_token", token);
    try {
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      return response.ok;
    } catch {
      return false;
    }
  }

  async updateUser(dto: IUserDto, token: string): Promise<IUserDto> {
    const url = new URL(`${this.baseUrl}/AppUsers/${dto.id}`);
    url.searchParams.append("access_token", token);
    return this.request<IUserDto>(url.toString(), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dto),
    });
  }

  async changeOwnPassword(props: { oldPassword: string; newPassword: string; token: string }): Promise<{
    userId: "string";
    success: true;
  }> {
    const { oldPassword, newPassword, token } = props;
    const url = new URL(`${this.baseUrl}/AppUsers/change-app-password`);
    url.searchParams.append("access_token", token);
    return this.request<{ userId: "string"; success: true }>(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    });
  }

  async resetPassword(props: { email: string }): Promise<{
    success: true;
    userId: string;
    email: string;
  }> {
    const { email } = props;
    const url = new URL(`${this.baseUrl}/AppUsers/requestPasswordReset`);
    url.searchParams.append("email", email);
    return this.request<{
      success: true;
      userId: string;
      email: string;
    }>(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export const authService = new AuthService();
