import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  label: {
    backgroundColor: "transparent",
  },
  headerTitle: {
    flexGrow: 1,
    maxWidth: 350,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: theme.spacing(2),
  },
  farmLandTitle: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
  },
}));
