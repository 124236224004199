import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import { NotificationsArea } from "./modules/notifications/containers/notifications/notifications-area";
import { AppRouter } from "./router";
import { MuiTheme } from "./styles/mui-theme";

export function App(): JSX.Element {
  return (
    <ThemeProvider theme={MuiTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <CssBaseline /*enableColorScheme={true} TODO: in mui v5+ */ />
        <AppRouter />
        <NotificationsArea />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
