import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  corner: {
    zIndex: 1000,
    position: "absolute",
  },
  topLeft: {
    top: 155,
    left: 12,
  },
}));
