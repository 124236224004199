import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  fetchAvailableFarmsAction,
  setSelectedFarmNameAction,
} from "../../../../../pages/map-page/store/map-page.slice";
import { useAppDispatch } from "../../../../../store";
import { getFilterComposition } from "../../../store/filters.selector";
import { FilterName } from "../../enums/filter-name";
import { FilterFieldArcgisName, useArcgisNameEffect } from "../filter-field-arcgis-name/filter-field-arcgis-name";
import { FilterFieldSeasonId, useSeasonEffect } from "../filter-field-season-id/filter-field-season-id";

export const FilterFarmAndSeason = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { ArcgisFarmName } = useSelector((state) => getFilterComposition(state, [FilterName.ArcgisFarmName]));

  useEffect(() => {
    if (!ArcgisFarmName) {
      return;
    }
    dispatch(setSelectedFarmNameAction(ArcgisFarmName));
  }, [dispatch, ArcgisFarmName]);

  // set defaults for season and farm
  useSeasonEffect();
  useArcgisNameEffect();

  // (initialize) get tuples eko/arcgis names
  useEffect(() => {
    dispatch(fetchAvailableFarmsAction());
  }, [dispatch]);

  return (
    <>
      <Grid container={true} spacing={1}>
        <Grid item={true}>
          <FilterFieldArcgisName />
        </Grid>

        <Grid item={true}>
          <FilterFieldSeasonId />
        </Grid>
      </Grid>
    </>
  );
};
