import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { Link } from "react-router-dom";

import { DialogWindowBtnActionsProps, DialogWindowProps } from "../../common-types";
import { dialogWindowStyles } from "./dialog-window-styles";

export const DialogWindowComponent = (props: DialogWindowProps): JSX.Element => {
  const { title, text, open, onCloseWindow, isCloseButton, closeButtonFunc, btnActions } = props;

  const classes = dialogWindowStyles();

  const renderButton = (item: DialogWindowBtnActionsProps) => {
    return (
      <Button
        className={item.variantBtn === "outlined" ? classes[item.colorBtn] : undefined}
        color={item.variantBtn === "contained" ? "primary" : undefined}
        variant={item.variantBtn}
        onClick={item.funcBtn}
        // eslint-disable-next-line
        autoFocus={item.autoFocusBtn}
      >
        {item.textBtn}
      </Button>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseWindow}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title} disableTypography={true}>
        <Typography variant="h5">{title}</Typography>
        {isCloseButton && (
          <IconButton aria-label="close" onClick={closeButtonFunc} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {text.map((value: string, index: number) => {
          return <DialogContentText key={index}>{value}</DialogContentText>;
        })}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {btnActions.length > 0 &&
          btnActions.map((item: DialogWindowBtnActionsProps, index: number) =>
            item.hrefBtn ? (
              <Link key={index} to={item.hrefBtn} className="link">
                {renderButton(item)}
              </Link>
            ) : (
              <React.Fragment key={index}>{renderButton(item)}</React.Fragment>
            )
          )}
      </DialogActions>
    </Dialog>
  );
};
