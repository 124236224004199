export const PATHS = {
  MAIN_PAGE: "/",
  CATALOG_PAGE: "/catalogs",
  LOGIN_PAGE: "/login",
  TECHOPERATIONS_PAGE: "/tech-operations",
  OBSERVATIONS_PAGE: "/observations",
  MAP_PAGE: "/map",
  FIELDS_PAGE: "/fields",
  DASHBOARD: "/dashboard",
  SETTINGS: "/settings",
  EDIT_PAGE: "editing",
  EDIT_NEW_PAGE: "new",
};

export const ISO8601_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const ISO8601_DATE_FORMAT = "yyyy-MM-dd";
export const HUMAN_DATE_FORMAT = "dd.MM.yyyy";
