import { Box, Grid } from "@material-ui/core";
import React, { PropsWithChildren, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Sidebar } from "../../../modules/sidebar";
import { useAppbarHeight } from "../../utils/use-app-bar-height";
import { useStyles } from "./common-layout.styles";

interface IProps {
  title?: string;
  contentPadding?: number;
}

// TODO: try this with Route element=<commonLayout...
export const CommonLayout = (props: PropsWithChildren<IProps>): JSX.Element => {
  const { children, title = "Эко кроп", contentPadding = 1 } = props;
  const { appbarHeight } = useAppbarHeight();
  const classes = useStyles({ appbarHeight });

  return (
    <Grid container={true} direction="row" wrap="nowrap">
      <Grid item={true}>
        <Sidebar title={title} />
      </Grid>

      <Grid item={true} xs>
        <div className={classes.toolbarHeight} />
        <Suspense fallback={<div>Loading...</div>}>
          <Box p={contentPadding}>{children ? children : <Outlet />}</Box>
        </Suspense>
      </Grid>
    </Grid>
  );
};
