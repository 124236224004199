import { CircularProgress } from "@material-ui/core";
import React from "react";

import { useProgressStyles } from "./progress-styles";

export const CircularProgressComponent = (props: { show: boolean }): JSX.Element => {
  const { show } = props;
  const classes = useProgressStyles();

  return (
    <>
      {show && (
        <div className={classes.background}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};
