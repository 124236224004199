import { CatalogSectionInfo } from "./catalog-tab-layout-types";

export enum CATALOG_SECTIONS_ENUM {
  TECHNICKS = "TechAssets", // данные по технике
  PERSONALS = "Employees", //"AppUsers", // данные по персоналу
  APPUSERS = "AppUsers", //"AppUsers", // данные по персоналу
  SZR = "Chemicals", // данные по СЗР
  FERTILISER = "Fertilisers", // данные по удобрениям
  SEEDS = "Seeds", // данные по семенам
  CROPS = "Crops", // данные по культурам
  PHENOPHASE = "PhenoPhases", // данные по сорнякам
  WEEDS = "Weeds", // данные по сорнякам
  PESTS = "Pests", // данные по вредителям
  DISEASES = "Diseases", // данные по болезням
}

export const CATALOG_SECTIONS_INFO: CatalogSectionInfo = {
  [CATALOG_SECTIONS_ENUM.TECHNICKS]: {
    name: "Техника",
    btnAddName: "Добавить технику",
  },
  [CATALOG_SECTIONS_ENUM.PERSONALS]: {
    name: "Персонал",
    btnAddName: "Добавить сотрудника",
  },
  [CATALOG_SECTIONS_ENUM.APPUSERS]: {
    name: "Пользователи",
    btnAddName: "Добавить пользователя",
  },
  [CATALOG_SECTIONS_ENUM.SZR]: {
    name: "СЗР",
    btnAddName: "Добавить СЗР",
  },
  [CATALOG_SECTIONS_ENUM.FERTILISER]: {
    name: "Удобрения",
    btnAddName: "Добавить удобрения",
  },
  [CATALOG_SECTIONS_ENUM.SEEDS]: {
    name: "Семена",
    btnAddName: "Добавить семена",
  },
  [CATALOG_SECTIONS_ENUM.CROPS]: {
    name: "Культуры",
    btnAddName: "Добавить культуру",
  },
  [CATALOG_SECTIONS_ENUM.PHENOPHASE]: {
    name: "Фенофазы",
    btnAddName: "Добавить фенофазу",
  },
  [CATALOG_SECTIONS_ENUM.WEEDS]: {
    name: "Сорняки",
    btnAddName: "Добавить сорняк",
  },
  [CATALOG_SECTIONS_ENUM.PESTS]: {
    name: "Вредители",
    btnAddName: "Добавить вредителя",
  },
  [CATALOG_SECTIONS_ENUM.DISEASES]: {
    name: "Болезни",
    btnAddName: "Добавить болезнь",
  },
};
