import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IAuthState } from "../shared/interfaces/auth-state";
import { MODULE_NAME } from "./auth.slice";

// eslint-disable-next-line
export const getAuthState = (rootState: any): IAuthState => rootState[MODULE_NAME];

export const getLoading = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.isLoading);
export const getIsAuthenticated = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.isAuthenticated);
export const getUser = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.user);
export const getToken = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.token);
export const getError = createDraftSafeSelector(getAuthState, (state: IAuthState) => state.error);
