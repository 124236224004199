import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { ChangeOwnPassword } from "../../../../authentication/containers/change-own-password/change-own-password";
import { EditUser } from "../../../../authentication/containers/edit-user/edit-user";
import { fetchFarmsAction } from "../../../../modules/farms";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { NotFound } from "../../../../shared/components/not-found/not-found";
import { SettingsSubmenu } from "../settings-submenu/settings-submenu";

export const Settings = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFarmsAction());
  }, [dispatch]);

  return (
    <CommonLayout title={"Настройки"}>
      <Grid container={true} direction="row" wrap="nowrap">
        <Grid item={true}>
          <SettingsSubmenu />
        </Grid>

        <Grid item={true} style={{ flexGrow: 1 }}>
          <Paper style={{ marginLeft: 8, padding: 8 }}>
            <Routes>
              <Route path={"appusers/*"}>
                <Route path={"info"} element={<EditUser />} />
                <Route path={"password"} element={<ChangeOwnPassword />} />
                <Route path={"*"} element={<Navigate to={"info"} />} />
              </Route>
              <Route path={"interface"} element={<NotFound />} />
              <Route path={"notifications"} element={<NotFound />} />
              <Route path={"support"} element={<NotFound />} />
              <Route path="*" element={<Navigate to={"appusers"} replace={true} />} />
            </Routes>
          </Paper>
        </Grid>
      </Grid>
    </CommonLayout>
  );
};
