import { IFilter } from "../../../../shared/interface";
import { BaseService } from "../../../../shared/services";
import { TResultPaginationFilter, normalizeFilter } from "../../../../shared/utils/normalize-filter";
import { ITechAssetsDto } from "../dtos/tech-assets";
import { TechAssetWithInclude } from "../models/tech-assets";

class TechAssetsService extends BaseService<ITechAssetsDto, Partial<IFilter>> {
  private path = "TechAssets";

  async get(id: string): Promise<ITechAssetsDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  // eslint-disable-next-line
  async add(data: ITechAssetsDto): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async update(data: ITechAssetsDto): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  async list(filter: Partial<IFilter>): Promise<ITechAssetsDto[]> {
    return this.fetch<ITechAssetsDto[], TResultPaginationFilter>(this.path, {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    }).then((items) =>
      items.map((item) => ({
        ...item,
        techEngineNum: item.techEngineNum || "",
        inventoryNumber: item.inventoryNumber || "",
        techFactoryNum: item.techFactoryNum || "",
        stateNumber: item.stateNumber || "",
      }))
    );
  }

  async listWithIncludes(): Promise<TechAssetWithInclude[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: {
        filter: {
          include: [
            {
              relation: "farms",
              scope: {
                fields: ["id"],
              },
            },
            {
              relation: "techOperationSubGroups",
              scope: {
                fields: ["id"],
              },
            },
          ],
        },
      },
    });
  }

  async listCount(filter: Partial<IFilter>): Promise<{ count: number }> {
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    });
  }
}

export const techAssetsService = new TechAssetsService();
