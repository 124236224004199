import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline as AddCircleOutlineIcon, Delete as DeleteIcon } from "@material-ui/icons";
import lodashGet from "lodash/get";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, ControllerRenderProps, useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { getList as getDiseases } from "../../../../modules/diseases/store";
import { getList as getPests } from "../../../../modules/pests/store";
import { getList as getWeeds } from "../../../../modules/weeds/store";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import {
  ObservationViolationType,
  ObservationViolationTypeHuman as violationTypes,
} from "../../../../shared/enums/observation-violation-type";
import { TLabel } from "../../../../shared/interface";

const violationDefaultValue = {
  problemType: null,
  name: null,
  problemId: null,
  rate: null,
};

export const ObservationViolationsList = (): JSX.Element => {
  const rhfMethods = useFormContext();
  const { errors, setValue } = rhfMethods;
  const watchFields = rhfMethods.watch();
  const allWeedViolations = useSelector(getWeeds);
  const allDiseaseViolations = useSelector(getDiseases);
  const allPestViolations = useSelector(getPests);

  const [violationsByTypes, setViolationsByTypes] = useState<Record<ObservationViolationType, TLabel[]>>({
    [ObservationViolationType.Weed]: [],
    [ObservationViolationType.Disease]: [],
    [ObservationViolationType.Pest]: [],
  });

  useEffect(() => {
    (async () => {
      setViolationsByTypes({
        [ObservationViolationType.Weed]: allWeedViolations.map((item) => item.asViolationType), // all
        [ObservationViolationType.Disease]: allDiseaseViolations.map((item) => item.asViolationType), // filter or all
        [ObservationViolationType.Pest]: allPestViolations.map((item) => item.asViolationType), // filter only
      });
    })();
  }, [allWeedViolations, allDiseaseViolations, allPestViolations, watchFields.cropTypeId]);

  const {
    fields: violations,
    append: appendViolation,
    remove: removeViolation,
  } = useFieldArray({
    name: "violations",
    control: rhfMethods.control,
    keyName: "_uid",
  });

  const getItemById = useCallback((collection, id) => {
    return collection?.find((item) => item.id === id) || null;
  }, []);

  const isViolationAddingDisabled = useMemo(() => {
    let result = false;
    Object.keys(violationTypes).forEach((key) => {
      if (result || violationsByTypes[key]?.length) {
        result = true;
      }
    });

    return !result;
  }, [violationsByTypes]);

  const onViolationTypeChange = useCallback((idx, value, setViolationProblemType) => {
    setViolationProblemType(value);
    rhfMethods.setValue(`violations.${idx}.name`, null);
    rhfMethods.setValue(`violations.${idx}.problemId`, null);
    rhfMethods.setValue(`violations.${idx}.rate`, null);
    // eslint-disable-next-line
  }, []);

  const onViolationChange = useCallback(
    (idx: number, value: TLabel | null, setViolationProblemId: ControllerRenderProps["onChange"]) => {
      if (!value) {
        return;
      }
      const { id, title } = value;
      setViolationProblemId(id);
      setValue(`violations.${idx}.name`, title);
    },
    [setValue]
  );

  return (
    <>
      <ListItem>
        <Typography variant={"h5"}>Нарушения</Typography>

        <Box clone={true} ml={2}>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => appendViolation({ ...violationDefaultValue })}
            disabled={isViolationAddingDisabled}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Box>
      </ListItem>

      {isViolationAddingDisabled ||
        violations.map((violation, idx) => {
          const problemType = rhfMethods.getValues(`violations.${idx}.problemType`) as ObservationViolationType;

          return (
            <ListItem key={violation._uid}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={true}>
                  <Controller
                    name={`violations.${idx}.problemType`}
                    control={rhfMethods.control}
                    rules={{ required: true }}
                    defaultValue={violation.problemType}
                    render={({ onChange, value }) => (
                      <FormControl
                        fullWidth={true}
                        error={Boolean(lodashGet(rhfMethods.formState.errors, `violations.${idx}.problemType`))}
                      >
                        <InputLabel id={`violations.${idx}.problemType`} required={true}>
                          Тип нарушения
                        </InputLabel>
                        <Select
                          labelId={`violations.${idx}.problemType`}
                          value={value || ""}
                          onChange={(evt) => onViolationTypeChange(idx, evt.target.value, onChange)}
                          required={true}
                        >
                          {Object.keys(violationTypes)
                            .filter((key) => violationsByTypes[key]?.length)
                            .map((key) => (
                              <MenuItem key={`violations.${idx}.problemType-${key}`} value={key}>
                                {violationTypes[key]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item={true} xs={true}>
                  <Controller
                    name={`violations.${idx}.name`}
                    control={rhfMethods.control}
                    defaultValue={violation.name}
                    render={({ value }) => <input type="hidden" value={value || ""} />}
                  />

                  <RHFAutocomplete<TLabel>
                    name={`violations.${idx}.problemId`}
                    rules={{ required: true }}
                    defaultValue={violation.problemId}
                    renderValue={(value) => getItemById(violationsByTypes[problemType], value)}
                    renderOnChange={(value, onChange) => onViolationChange(idx, value, onChange)}
                    AutocompleteProps={{
                      disabled: !problemType,
                      options: problemType ? violationsByTypes[problemType] : [],
                      noOptionsText: "Нет возможных нарушений",
                    }}
                    TextFieldProps={{
                      label: "Нарушение",
                      required: true,
                    }}
                  />
                </Grid>

                <Grid item={true} xs={true}>
                  <RHFTextField
                    name={`violations.${idx}.rate`}
                    rules={{
                      required: true,
                      min: {
                        value: 0.001,
                        message: "Значение должно быть больше нуля",
                      },
                    }}
                    defaultValue={violation.rate}
                    TextFieldProps={{
                      type: "number",
                      label: "Интенсивность",
                      required: true,
                      error: Boolean(errors.violations && errors.violations[idx]),
                      helperText: errors.violations && errors.violations[idx] && errors.violations[idx].rate.message,
                    }}
                  />
                </Grid>

                <Grid item={true}>
                  <IconButton onClick={() => removeViolation(idx)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
    </>
  );
};
